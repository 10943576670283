import React, { useState, useEffect } from 'react';
import axios from 'axios';

const RecentMessagesList = () => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_LOCALHOST}messages/recent`);
        setMessages(response.data);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };
    fetchMessages();
  }, []);

  return (
    <div className="overflow-hidden">
      <ul className="divide-y divide-gray-200">
        {messages.map((message) => (
          <li key={message.id} className="py-3">
            <div>
              <p className="text-sm font-medium text-gray-900">{message.sender}</p>
              <p className="text-sm text-gray-500 truncate">{message.content}</p>
              <p className="text-xs text-gray-400 mt-1">
                {new Date(message.timestamp).toLocaleString()}
              </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RecentMessagesList; 