import React, { useEffect, useState } from 'react';
import { handleError, handleSuccess } from '../../utils';
import { ToastContainer } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';

export default function Signup() {
    const api = process.env.REACT_APP_LOCALHOST;
    const navigate = useNavigate();

    const [data, setdata] = useState({
        name: '',
        email: '',
        password: '',
        userpackage: '',
        paymentStatus: 'unpaid',
        packagename: '',
    });
    const [amount, setamount] = useState([]);

    useEffect(() => {
        fetch(`${api}pricing`)
            .then((response) => response.json())
            .then((data) => setamount(data))
            .catch((error) => console.error('Error:', error));
    }, [api]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'userpackage') {
            const selectedPackage = amount.find(plan => plan.price === value);
            setdata({
                ...data,
                userpackage: value,
                packagename: selectedPackage ? selectedPackage.label : '',
            });
        } else {
            setdata({
                ...data,
                [name]: value
            });
        }
    };

    const handleSignup = async (e) => {
        e.preventDefault();

        const { name, email, password, userpackage } = data;

        if (!name || !email || !password || !userpackage) {
            return handleError('Name, email, password, and package must be specified');
        }

        try {
            const url = `${api}users/signup`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return handleError(errorData.error || errorData.message);
            }

            const result = await response.json();
            const { success, message } = result;
            if (success) {
                handleSuccess(message);
                setTimeout(() => {
                    navigate('/login');
                }, 1000);
            }
        } catch (err) {
            handleError(err);
        }
    };

    return (
        <div className="flex flex-col md:flex-row bg-gradient-to-r from-indigo-500 to-purple-600 min-h-screen">
            {/* Left Side - Description */}
            <div className="flex-1 flex items-center justify-center p-8 text-white">
                <div className="max-w-md">
                    <h1 className="text-4xl font-bold mb-4">Welcome to Peerless Resume</h1>
                    <p className="mb-6">
                        Join us to create a professional resume that stands out. Our expert team is dedicated to helping you land your dream job with tailored resume writing services.
                    </p>
                    <p>
                        Sign up now to get started on your journey towards a successful career. We offer various packages to suit your needs.
                    </p>
                </div>
            </div>

            {/* Right Side - Signup Form */}
            <div className="flex-1 flex items-center justify-center p-8">
                <form onSubmit={handleSignup} className="bg-white rounded-lg shadow-lg p-8 w-full max-w-md">
                    <h2 className="mb-6 text-3xl font-bold text-gray-800 text-center">Create Your Account</h2>
                    <div className="flex flex-col gap-6">
                        <div>
                            <label htmlFor="name" className="mb-2 block text-sm text-gray-800">Name</label>
                            <input name="name" value={data.name} onChange={handleChange} className="w-full rounded border bg-gray-50 px-4 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-200 focus:ring" />
                        </div>

                        <div>
                            <label htmlFor="userpackage" className="mb-2 block text-sm text-gray-800">Select Package</label>
                            <select name="userpackage" value={data.userpackage} onChange={handleChange} className="w-full rounded border bg-gray-50 px-4 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-200 focus:ring">
                                <option value="" disabled>Select a package</option>
                                {amount.map((plan, index) => (
                                    <option key={index} value={plan.price}>
                                        {plan.label} - {plan.price}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <label htmlFor="email" className="mb-2 block text-sm text-gray-800">Email</label>
                            <input name="email" value={data.email} onChange={handleChange} className="w-full rounded border bg-gray-50 px-4 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-200 focus:ring" />
                        </div>

                        <div>
                            <label htmlFor="password" className="mb-2 block text-sm text-gray-800">Password</label>
                            <input type="password" name="password" value={data.password} onChange={handleChange} className="w-full rounded border bg-gray-50 px-4 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-200 focus:ring" />
                        </div>

                        <button type="submit" className="block rounded-lg bg-indigo-600 px-8 py-3 text-center text-sm font-semibold text-white transition duration-200 hover:bg-indigo-500 focus:outline-none focus:ring active:bg-indigo-700">Sign Up</button>
                    </div>

                    <div className="mt-6 text-center">
                        <p className="text-sm text-gray-500">Already have an account? <Link to='/login' className="text-indigo-600 hover:underline">Login</Link></p>
                    </div>
                </form>
            </div>
            <ToastContainer />
        </div>
    );
}
