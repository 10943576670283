import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { FaSearch, FaTrash, FaEnvelope, FaEdit, FaDownload } from 'react-icons/fa';
import AdminLayout from '../components/AdminLayout';

const Newsletter = () => {
  // Fake data
  const dummySubscribers = [
    {
      _id: "1",
      name: "John Smith",
      email: "john.smith@example.com",
      phone: "+1 (555) 123-4567",
      subscribeDate: "2024-03-15"
    },
    {
      _id: "2",
      name: "Emma Johnson",
      email: "emma.j@example.com",
      phone: "+1 (555) 234-5678",
      subscribeDate: "2024-03-14"
    },
    {
      _id: "3",
      name: "Michael Davis",
      email: "michael.d@example.com",
      phone: "+1 (555) 345-6789",
      subscribeDate: "2024-03-13"
    },
    {
      _id: "4",
      name: "Sarah Wilson",
      email: "sarah.w@example.com",
      phone: "+1 (555) 456-7890",
      subscribeDate: "2024-03-12"
    },
    {
      _id: "5",
      name: "David Brown",
      email: "david.b@example.com",
      phone: "+1 (555) 567-8901",
      subscribeDate: "2024-03-11"
    },
    {
      _id: "6",
      name: "Lisa Anderson",
      email: "lisa.a@example.com",
      phone: "+1 (555) 678-9012",
      subscribeDate: "2024-03-10"
    },{
      _id: "7",
      name: "John Smith",
      email: "john.smith@example.com",
      phone: "+1 (555) 123-4567",
      subscribeDate: "2024-03-15"
    },
    {
      _id: "8",
      name: "Emma Johnson",
      email: "emma.j@example.com",
      phone: "+1 (555) 234-5678",
      subscribeDate: "2024-03-14"
    },
    {
      _id: "9",
      name: "Michael Davis",
      email: "michael.d@example.com",
      phone: "+1 (555) 345-6789",
      subscribeDate: "2024-03-13"
    },
    {
      _id: "10",
      name: "Sarah Wilson",
      email: "sarah.w@example.com",
      phone: "+1 (555) 456-7890",
      subscribeDate: "2024-03-12"
    },
    {
      _id: "11",
      name: "David Brown",
      email: "david.b@example.com",
      phone: "+1 (555) 567-8901",
      subscribeDate: "2024-03-11"
    },
    {
      _id: "12",
      name: "Lisa Anderson",
      email: "lisa.a@example.com",
      phone: "+1 (555) 678-9012",
      subscribeDate: "2024-03-10"
    },{
      _id: "13",
      name: "John Smith",
      email: "john.smith@example.com",
      phone: "+1 (555) 123-4567",
      subscribeDate: "2024-03-15"
    },
    {
      _id: "14",
      name: "Emma Johnson",
      email: "emma.j@example.com",
      phone: "+1 (555) 234-5678",
      subscribeDate: "2024-03-14"
    },
    {
      _id: "15",
      name: "Michael Davis",
      email: "michael.d@example.com",
      phone: "+1 (555) 345-6789",
      subscribeDate: "2024-03-13"
    },
    {
      _id: "16",
      name: "Sarah Wilson",
      email: "sarah.w@example.com",
      phone: "+1 (555) 456-7890",
      subscribeDate: "2024-03-12"
    },
    {
      _id: "17",
      name: "David Brown",
      email: "david.b@example.com",
      phone: "+1 (555) 567-8901",
      subscribeDate: "2024-03-11"
    },
    {
      _id: "18",
      name: "Lisa Anderson",
      email: "lisa.a@example.com",
      phone: "+1 (555) 678-9012",
      subscribeDate: "2024-03-10"
    }
  ];

  // States
  const [subscribers, setSubscribers] = useState(dummySubscribers);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSubscribers, setSelectedSubscribers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const tableContainerRef = useRef(null);
  const cardContainerRef = useRef(null);
  
  const ITEMS_PER_PAGE = {
    MOBILE: 4,
    DESKTOP: 10
  };

  // Add mobile-specific states
  const [mobilePage, setMobilePage] = useState(1);
  const [mobileLoading, setMobileLoading] = useState(false);
  const [mobileHasMore, setMobileHasMore] = useState(true);

  // Filter subscribers
  const filteredSubscribers = useMemo(() => {
    return subscribers.filter(sub =>
      sub.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      sub.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      sub.phone.includes(searchTerm)
    );
  }, [subscribers, searchTerm]);

  // Separate data getters for mobile and desktop
  const getMobileData = useCallback(() => {
    const endIndex = mobilePage * ITEMS_PER_PAGE.MOBILE;
    return filteredSubscribers.slice(0, endIndex);
  }, [filteredSubscribers, mobilePage]);

  const getDesktopData = useCallback(() => {
    const endIndex = page * ITEMS_PER_PAGE.DESKTOP;
    return filteredSubscribers.slice(0, endIndex);
  }, [filteredSubscribers, page]);

  // Separate scroll handlers
  const handleCardScroll = useCallback((e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    
    if (scrollHeight - scrollTop - clientHeight < 50) {
      if (!mobileLoading && mobileHasMore) {
        setMobileLoading(true);
        // Simulate API call
        setTimeout(() => {
          if (getMobileData().length < filteredSubscribers.length) {
            setMobilePage(prev => prev + 1);
          }
          setMobileLoading(false);
        }, 500);
      }
    }
  }, [mobileLoading, mobileHasMore, getMobileData, filteredSubscribers.length]);

  const handleTableScroll = useCallback((e) => {
    if (loading || !hasMore) return;

    const { scrollTop, scrollHeight, clientHeight } = e.target;
    
    if (scrollHeight - scrollTop - clientHeight < 100) {
      setLoading(true);
      setTimeout(() => {
        setPage(prev => prev + 1);
        setLoading(false);
      }, 500);
    }
  }, [loading, hasMore]);

  // Update mobile hasMore effect
  useEffect(() => {
    setMobileHasMore(getMobileData().length < filteredSubscribers.length);
  }, [getMobileData, filteredSubscribers.length]);

  // Reset pagination on search
  useEffect(() => {
    setMobilePage(1);
    setPage(1);
  }, [searchTerm]);

  // Handler Functions
  const handleBulkEmail = useCallback(() => {
    if (selectedSubscribers.length === 0) {
      alert('Please select at least one subscriber');
      return;
    }
    
    const selectedEmails = subscribers
      .filter(sub => selectedSubscribers.includes(sub._id))
      .map(sub => sub.email)
      .join(',');
    
    window.location.href = `mailto:${selectedEmails}`;
  }, [selectedSubscribers, subscribers]);

  const handleExportCSV = useCallback(() => {
    if (subscribers.length === 0) return;

    const headers = ['Name', 'Email', 'Phone', 'Subscribe Date'];
    const csvData = subscribers.map(sub => [
      sub.name,
      sub.email,
      sub.phone,
      new Date(sub.subscribeDate).toLocaleDateString()
    ]);

    const csvContent = [
      headers.join(','),
      ...csvData.map(row => row.join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    
    link.setAttribute('href', url);
    link.setAttribute('download', 'subscribers.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [subscribers]);

  const handleDelete = useCallback((id) => {
    if (window.confirm('Are you sure you want to delete this subscriber?')) {
      // Here you would typically make an API call
      setSubscribers(prev => prev.filter(sub => sub._id !== id));
      setSelectedSubscribers(prev => prev.filter(selectedId => selectedId !== id));
    }
  }, []);

  const handleEdit = useCallback((id) => {
    // Here you would typically navigate to edit page or open modal
    console.log('Edit subscriber:', id);
    // Example:
    // navigate(`/admin/newsletter/edit/${id}`);
    // or
    // setEditModalOpen(true);
    // setEditingSubscriber(subscribers.find(sub => sub._id === id));
  }, []);

  return (
    <AdminLayout>
      {/* Page Header */}
      <div className="mb-6 md:mb-8">
        <h1 className="text-2xl md:text-3xl font-bold text-gray-900">Newsletter Subscribers</h1>
        <p className="mt-2 text-sm md:text-base text-gray-600">
          Manage your newsletter subscribers and send bulk emails
        </p>
      </div>

      {/* Actions Bar */}
      <div className="mb-6 flex flex-col md:flex-row gap-4 items-stretch md:items-center">
        <div className="w-full md:flex-1">
          <div className="relative">
            <input
              type="text"
              placeholder="Search subscribers..."
              className="w-full pl-10 pr-4 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-purple-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <FaSearch className="absolute left-3 top-3 text-gray-400" />
          </div>
        </div>
        
        <div className="flex gap-2 md:gap-3">
          <button
            onClick={handleBulkEmail}
            disabled={selectedSubscribers.length === 0}
            className="flex-1 md:flex-none px-3 md:px-4 py-2 bg-purple-500 text-white rounded-lg hover:bg-purple-600 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2 text-sm md:text-base"
          >
            <FaEnvelope className="shrink-0" />
            <span className="hidden md:inline">Send Bulk Email</span>
            <span className="md:hidden">Email</span>
          </button>
          <button
            onClick={handleExportCSV}
            className="flex-1 md:flex-none px-3 md:px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 flex items-center justify-center gap-2 text-sm md:text-base"
          >
            <FaDownload className="shrink-0" />
            <span className="hidden md:inline">Export CSV</span>
            <span className="md:hidden">Export</span>
          </button>
        </div>
      </div>

      {/* Content Area */}
      <div className="bg-white rounded-lg shadow-sm">
        {/* Mobile Card View */}
        <div className="block md:hidden">
          <div 
            className="bg-gray-50 rounded-lg p-4"
            style={{ height: 'calc(100vh - 220px)' }}
          >
            <div 
              ref={cardContainerRef}
              className="h-full overflow-y-auto space-y-4 pr-2"
              onScroll={handleCardScroll}
            >
              {getMobileData().map((subscriber) => (
                <div
                  key={subscriber._id}
                  className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200 mb-4"
                >
                  {/* Card Content */}
                  <div className="p-4">
                    <div className="flex flex-col space-y-2">
                      <div>
                        <h3 className="text-lg font-medium text-gray-900">
                          {subscriber.name}
                        </h3>
                        <p className="text-sm text-gray-500 mt-1">{subscriber.email}</p>
                      </div>
                      <div className="flex items-center text-gray-500">
                        <span className="text-sm">{subscriber.phone}</span>
                      </div>
                    </div>
                  </div>

                  {/* Card Footer */}
                  <div className="px-4 py-3 bg-gray-50 border-t border-gray-100 rounded-b-lg">
                    <div className="flex justify-end space-x-2">
                      <button
                        onClick={() => handleEdit(subscriber._id)}
                        className="px-3 py-1.5 text-sm font-medium text-indigo-600 hover:text-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 rounded-md"
                      >
                        <span className="flex items-center space-x-1">
                          <FaEdit className="w-4 h-4" />
                          <span>Edit</span>
                        </span>
                      </button>
                      <a
                        href={`mailto:${subscriber.email}`}
                        className="px-3 py-1.5 text-sm font-medium text-blue-600 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 rounded-md"
                      >
                        <span className="flex items-center space-x-1">
                          <FaEnvelope className="w-4 h-4" />
                          <span>Email</span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              ))}

              {/* Loading Indicator */}
              {mobileLoading && (
                <div className="flex justify-center py-2">
                  <div className="animate-spin rounded-full h-6 w-6 border-2 border-purple-500 border-t-transparent"></div>
                </div>
              )}

              {/* End of Content Message */}
              {!mobileHasMore && getMobileData().length > 0 && (
                <div className="text-center py-2 text-gray-500 text-sm">
                  No more subscribers to load
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Desktop Table View */}
        <div className="hidden md:block bg-white rounded-lg shadow overflow-hidden">
          <div 
            ref={tableContainerRef}
            className="overflow-auto max-h-[calc(100vh-250px)]"
            onScroll={handleTableScroll}
          >
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 sticky top-0 z-10">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-10">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setSelectedSubscribers(e.target.checked ? subscribers.map(sub => sub._id) : []);
                      }}
                      className="rounded text-purple-500 focus:ring-purple-500"
                    />
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Email
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Phone
                  </th>
                  <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider w-32">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {getDesktopData().map((subscriber, index) => (
                  <tr 
                    key={subscriber._id}
                    className="hover:bg-gray-50"
                  >
                    <td className="px-6 py-4 whitespace-nowrap">
                      <input
                        type="checkbox"
                        checked={selectedSubscribers.includes(subscriber._id)}
                        onChange={(e) => {
                          setSelectedSubscribers(
                            e.target.checked 
                              ? [...selectedSubscribers, subscriber._id]
                              : selectedSubscribers.filter(id => id !== subscriber._id)
                          );
                        }}
                        className="rounded text-purple-500 focus:ring-purple-500"
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">{subscriber.name}</div>
                      {/* Show email on mobile */}
                      <div className="md:hidden text-xs text-gray-500 mt-1">{subscriber.email}</div>
                    </td>
                    <td className="hidden md:table-cell px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">{subscriber.email}</div>
                    </td>
                    <td className="hidden md:table-cell px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">{subscriber.phone}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <div className="flex justify-end gap-2 md:gap-3">
                        <button
                          onClick={() => handleEdit(subscriber._id)}
                          className="p-1 text-indigo-600 hover:text-indigo-900"
                        >
                          <FaEdit className="w-4 h-4 md:w-5 md:h-5" />
                        </button>
                        <a
                          href={`mailto:${subscriber.email}`}
                          className="p-1 text-blue-600 hover:text-blue-900"
                        >
                          <FaEnvelope className="w-4 h-4 md:w-5 md:h-5" />
                        </a>
                        <button
                          onClick={() => handleDelete(subscriber._id)}
                          className="p-1 text-red-600 hover:text-red-900"
                        >
                          <FaTrash className="w-4 h-4 md:w-5 md:h-5" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Loading Indicator */}
            {loading && (
              <div className="flex justify-center py-4 bg-white border-t">
                <div className="animate-spin rounded-full h-8 w-8 border-2 border-purple-500 border-t-transparent"></div>
              </div>
            )}

            {/* End of Content Message */}
            {!hasMore && getDesktopData().length > 0 && (
              <div className="text-center py-4 text-gray-500 border-t">
                No more subscribers to load
              </div>
            )}
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Newsletter;
