import React, { useState, useEffect } from 'react';
import axios from 'axios';

const RecentOrdersList = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_LOCALHOST}orders/recent`);
        setOrders(response.data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };
    fetchOrders();
  }, []);

  return (
    <div className="overflow-hidden">
      <ul className="divide-y divide-gray-200">
        {orders.map((order) => (
          <li key={order.id} className="py-3">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm font-medium text-gray-900">{order.service}</p>
                <p className="text-sm text-gray-500">{order.customerName}</p>
              </div>
              <div className="text-sm text-gray-500">
                ${order.amount}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RecentOrdersList; 