import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { FaSearch, FaSpinner, FaEnvelope, FaDownload, FaEye, FaFilePdf, FaFileWord, FaFile } from 'react-icons/fa';
import AdminLayout from '../components/AdminLayout';

const ResumeEvaluation = () => {
  const api = process.env.REACT_APP_LOCALHOST;
  const files = process.env.REACT_APP_FILES;
  const [resumes, setResumes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [selectedResume, setSelectedResume] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const tableContainerRef = useRef(null);
  const cardContainerRef = useRef(null);
  const ITEMS_PER_PAGE = {
    MOBILE: 4,
    DESKTOP: 10
  };
  const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: ''
  });

  useEffect(() => {
    fetchResumes();
  }, []);

  const fetchResumes = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${api}resumes`);
      const data = await response.json();
      setResumes(data);
    } catch (error) {
      console.error("Error fetching resumes:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const getFileIcon = (filename) => {
    if (filename.toLowerCase().endsWith('.pdf')) return <FaFilePdf className="w-6 h-6 text-red-500" />;
    if (filename.toLowerCase().endsWith('.doc') || filename.toLowerCase().endsWith('.docx')) {
      return <FaFileWord className="w-6 h-6 text-blue-500" />;
    }
    return <FaFile className="w-6 h-6 text-gray-500" />;
  };

  const filteredResumes = useMemo(() => {
    return resumes.filter(resume => {
      const matchesSearch = 
        resume.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        resume.name?.toLowerCase().includes(searchTerm.toLowerCase());

      const resumeDate = new Date(resume.createdAt);
      const startDate = dateRange.startDate ? new Date(dateRange.startDate) : null;
      const endDate = dateRange.endDate ? new Date(dateRange.endDate) : null;

      const matchesDateRange = 
        (!startDate || resumeDate >= startDate) &&
        (!endDate || resumeDate <= endDate);

      return matchesSearch && matchesDateRange;
    });
  }, [resumes, searchTerm, dateRange]);

  const getPaginatedData = useCallback(() => {
    const isMobile = window.innerWidth < 768;
    const itemsPerPage = isMobile ? ITEMS_PER_PAGE.MOBILE : ITEMS_PER_PAGE.DESKTOP;
    const endIndex = page * itemsPerPage;
    return filteredResumes.slice(0, endIndex);
  }, [filteredResumes, page]);

  const handleScroll = useCallback((e) => {
    if (loading || !hasMore) return;

    const { scrollTop, scrollHeight, clientHeight } = e.target;
    
    if (scrollHeight - scrollTop <= clientHeight * 1.5) {
      setLoading(true);
      setTimeout(() => {
        setPage(prev => prev + 1);
        setLoading(false);
      }, 500);
    }
  }, [loading, hasMore]);

  useEffect(() => {
    setHasMore(filteredResumes.length > page * ITEMS_PER_PAGE.DESKTOP);
  }, [filteredResumes, page]);

  useEffect(() => {
    setPage(1);
  }, [searchTerm, dateRange]);

  const handleViewResume = (resume) => {
    setSelectedResume(resume);
    setShowPreview(true);
  };

  const handleResetDateFilter = () => {
    setDateRange({
      startDate: '',
      endDate: ''
    });
  };

  return (
    <AdminLayout>
      {/* Header */}
      <div className="mb-6">
        <h1 className="text-2xl font-bold text-gray-900">Resume Evaluations</h1>
        <p className="mt-2 text-gray-600">Review and evaluate submitted resumes</p>
      </div>

      {/* Search and Filter Bar */}
      <div className="mb-6 flex flex-col md:flex-row gap-4">
        {/* Search Input */}
        <div className="relative flex-1">
          <FaSearch className="absolute left-3 top-3 text-gray-400" />
          <input
            type="text"
            placeholder="Search by email or name..."
            className="w-full pl-10 pr-4 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-purple-500"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {/* Date Filters */}
        <div className="flex flex-wrap gap-2 md:gap-4 items-center">
          <div className="flex items-center gap-2">
            <input
              type="date"
              value={dateRange.startDate}
              onChange={(e) => setDateRange(prev => ({ ...prev, startDate: e.target.value }))}
              className="px-3 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-purple-500"
              placeholder="Start Date"
            />
            <span className="text-gray-500">to</span>
            <input
              type="date"
              value={dateRange.endDate}
              onChange={(e) => setDateRange(prev => ({ ...prev, endDate: e.target.value }))}
              className="px-3 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-purple-500"
              placeholder="End Date"
            />
          </div>

          {/* Reset Filter Button */}
          {(dateRange.startDate || dateRange.endDate) && (
            <button
              onClick={handleResetDateFilter}
              className="px-3 py-2 text-sm text-gray-600 hover:text-gray-900 transition-colors"
            >
              Clear Dates
            </button>
          )}
        </div>
      </div>

      {/* Mobile View */}
      <div className="block md:hidden">
        <div 
          className="bg-gray-50 rounded-lg p-4"
          style={{ height: 'calc(100vh - 220px)' }}
        >
          <div 
            ref={cardContainerRef}
            className="h-full overflow-y-auto space-y-4 pr-2"
            onScroll={handleScroll}
          >
            {getPaginatedData().map((resume) => (
              <div key={resume._id} className="bg-white rounded-lg shadow-sm p-4">
                <div className="flex justify-between items-start mb-3">
                  <div>
                    <h3 className="font-medium text-gray-900">{resume.name}</h3>
                    <p className="text-sm text-gray-500">{resume.email}</p>
                    <p className="text-xs text-gray-400 mt-1">{formatDate(resume.createdAt)}</p>
                  </div>
                </div>
                
                <div className="flex items-center space-x-2 mt-3 pt-3 border-t">
                  {getFileIcon(resume.resumeFile)}
                  <span className="text-sm text-gray-600">
                    {resume.resumeFile.split('/').pop()}
                  </span>
                </div>
                
                <div className="flex justify-end space-x-3 mt-4">
                  <button
                    onClick={() => handleViewResume(resume)}
                    className="p-2 text-purple-600 hover:text-purple-900"
                  >
                    <FaEye className="w-5 h-5" />
                  </button>
                  <a
                    href={`${files}${resume.resumeFile}`}
                    download
                    className="p-2 text-blue-600 hover:text-blue-900"
                  >
                    <FaDownload className="w-5 h-5" />
                  </a>
                  <a
                    href={`mailto:${resume.email}`}
                    className="p-2 text-green-600 hover:text-green-900"
                  >
                    <FaEnvelope className="w-5 h-5" />
                  </a>
                </div>
              </div>
            ))}

            {/* Loading Indicator */}
            {loading && (
              <div className="flex justify-center py-4">
                <FaSpinner className="w-6 h-6 text-purple-500 animate-spin" />
              </div>
            )}

            {/* End of Content Message */}
            {!hasMore && getPaginatedData().length > 0 && (
              <div className="text-center py-4 text-gray-500">
                No more resumes to load
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Tablet/Desktop View */}
      <div className="hidden md:flex gap-6">
        {/* Resumes List */}
        <div className={`${showPreview ? 'w-1/2' : 'w-full'} transition-all duration-300`}>
          <div className="bg-white rounded-lg shadow-sm overflow-hidden">
            <div 
              ref={tableContainerRef}
              className="overflow-x-auto max-h-[calc(100vh-280px)]"
              onScroll={handleScroll}
            >
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50 sticky top-0 z-10">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Name/Email
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Resume File
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Submission Date
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {getPaginatedData().map((resume) => (
                    <tr key={resume._id} className="hover:bg-gray-50">
                      <td className="px-6 py-4">
                        <div className="text-sm font-medium text-gray-900">{resume.name}</div>
                        <div className="text-sm text-gray-500">{resume.email}</div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="flex items-center space-x-2">
                          {getFileIcon(resume.resumeFile)}
                          <span className="text-sm text-gray-500">
                            {resume.resumeFile.split('/').pop()}
                          </span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-500">
                          {formatDate(resume.createdAt)}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex space-x-4">
                          <button
                            onClick={() => handleViewResume(resume)}
                            className="text-purple-600 hover:text-purple-900"
                            title="View Resume"
                          >
                            <FaEye className="w-5 h-5" />
                          </button>
                          <a
                            href={`${files}${resume.resumeFile}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-600 hover:text-blue-900"
                            title="Download Resume"
                          >
                            <FaDownload className="w-5 h-5" />
                          </a>
                          <a
                            href={`mailto:${resume.email}`}
                            className="text-green-600 hover:text-green-900"
                            title="Send Evaluation"
                          >
                            <FaEnvelope className="w-5 h-5" />
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* Loading Indicator */}
              {loading && (
                <div className="flex justify-center py-4 bg-white border-t">
                  <FaSpinner className="w-6 h-6 text-purple-500 animate-spin" />
                </div>
              )}

              {/* End of Content Message */}
              {!hasMore && getPaginatedData().length > 0 && (
                <div className="text-center py-4 text-gray-500 border-t">
                  No more resumes to load
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Resume Preview Panel */}
        {showPreview && selectedResume && (
          <div className="w-1/2">
            <div className="bg-white rounded-lg shadow-sm h-full flex flex-col">
              <div className="p-4 border-b flex justify-between items-center">
                <h2 className="text-lg font-semibold">Resume Preview</h2>
                <button
                  onClick={() => setShowPreview(false)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  ×
                </button>
              </div>
              <div className="flex-1 p-4">
                {selectedResume.resumeFile.toLowerCase().endsWith('.pdf') ? (
                  <iframe
                    src={`${files}${selectedResume.resumeFile}`}
                    className="w-full h-full rounded border"
                    title="Resume Preview"
                  />
                ) : (
                  <div className="flex flex-col items-center justify-center h-full">
                    {getFileIcon(selectedResume.resumeFile)}
                    <p className="mt-4 text-gray-600">
                      This file type cannot be previewed. Please download to view.
                    </p>
                    <a
                      href={`${files}${selectedResume.resumeFile}`}
                      download
                      className="mt-4 px-4 py-2 bg-purple-500 text-white rounded-lg hover:bg-purple-600 transition-colors flex items-center gap-2"
                    >
                      <FaDownload />
                      Download Resume
                    </a>
                  </div>
                )}
              </div>
              <div className="p-4 border-t">
                <div className="flex gap-3 justify-end">
                  <a
                    href={`${files}${selectedResume.resumeFile}`}
                    download
                    className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors flex items-center gap-2"
                  >
                    <FaDownload />
                    Download
                  </a>
                  <a
                    href={`mailto:${selectedResume.email}`}
                    className="px-4 py-2 bg-purple-500 text-white rounded-lg hover:bg-purple-600 transition-colors flex items-center gap-2"
                  >
                    <FaEnvelope />
                    Send Evaluation
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </AdminLayout>
  );
};

export default ResumeEvaluation;
