import React from 'react';
import Header from './Header';
import AdminSidebar from './Sidebar';

const AdminLayout = ({ children }) => {
  return (
    <div className="flex flex-col md:flex-row min-h-screen bg-violet-50">
      {/* Sidebar - Always visible on desktop, toggleable on mobile */}
      <AdminSidebar />
      
      {/* Main Content Area with Header */}
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header />
        
        {/* Main Content */}
        <main className="flex-1 overflow-y-auto bg-violet-50 p-4 md:p-6">
          {children}
        </main>
      </div>
    </div>
  );
};

export default AdminLayout; 