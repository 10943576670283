import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserCircleIcon, BellIcon } from '@heroicons/react/24/outline';

const Header = () => {
  const [userData, setUserData] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    setUserData(localStorage.getItem('logedinUser'));
  }, []);

  return (
    <header className="bg-white shadow-sm sticky top-0 z-50">
      <div className="flex justify-between items-center px-4 sm:px-6 py-3">
        <div className="flex items-center">
          <Link to="/">
            <img
              src="/images/Peerless Logo.svg"
              alt="Peerless Resume"
              className="w-60 h-8 sm:h-10"
            />
          </Link>
        </div>

        <div className="flex items-center space-x-2 sm:space-x-4">
          <div className="relative">
            <BellIcon className="h-5 w-5 sm:h-6 sm:w-6 text-gray-500 cursor-pointer" />
            <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
              2
            </span>
          </div>

          <div className="relative">
            <div 
              className="flex items-center space-x-2 cursor-pointer"
              onClick={() => setShowDropdown(!showDropdown)}
            >
              <UserCircleIcon className="h-7 w-7 sm:h-8 sm:w-8 text-gray-500" />
              <span className="hidden sm:inline text-gray-700 font-medium">{userData || 'User'}</span>
            </div>

            {showDropdown && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50">
                <Link to="/profile" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                  Profile
                </Link>
                <button 
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  onClick={() => {
                    localStorage.removeItem('logedinUser');
                    window.location.href = '/login';
                  }}
                >
                  Sign out
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
