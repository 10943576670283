// src/adminpanel/components/AdminSidebar.jsx
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Icon from './Icon';

const AdminSidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const menuItems = [
    {
      title: 'Dashboard',
      path: '/admin',
      icon: 'dashboard',
    },
    {
      title: 'Communication',
      items: [
        { title: 'Chat', path: '/admin/chat', icon: 'chat' },
        { title: 'Contact Messages', path: '/admin/contact', icon: 'mail' },
        { title: 'Newsletter', path: '/admin/newsletter', icon: 'newspaper' },
      ]
    },
    {
      title: 'Orders & Services',
      items: [
        { title: 'Orders', path: '/admin/orders', icon: 'shopping-cart' },
        { title: 'Resume Evaluation', path: '/admin/resume-evaluation', icon: 'document-check' },
        { title: 'Questionnaire', path: '/admin/questionnaire', icon: 'clipboard-list' },
      ]
    },
    {
      title: 'User Management',
      items: [
        { title: 'User Info', path: '/admin/Userinfo', icon: 'users' },
      ]
    }
  ];

  return (
    <>
      {/* Mobile Menu Button */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="md:hidden fixed top-4 left-4 z-50 p-2 rounded-md bg-purple-500 text-white"
      >
        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          {isOpen ? (
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          ) : (
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
          )}
        </svg>
      </button>

      {/* Sidebar */}
      <div className={`${
        isOpen ? 'translate-x-0' : '-translate-x-full'
      } md:translate-x-0 fixed md:relative z-40 transition-transform duration-300 ease-in-out`}>
        <div className="flex flex-col w-64 h-full">
          <div className="flex flex-col flex-grow pt-5 pb-4 overflow-y-auto bg-gray-100 border-r">
            {/* Logo */}
            <div className="flex items-center justify-between px-4">
              <img className="h-8 w-auto" src="../images/Peerless Logo.svg" alt="Logo" />
              <button
                onClick={() => setIsOpen(false)}
                className="md:hidden p-2 rounded-md text-gray-500 hover:text-gray-600"
              >
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            {/* Navigation */}
            <nav className="flex-1 mt-8 px-2">
              {menuItems.map((section, index) => (
                <div key={index} className="mb-8">
                  {section.title && (
                    <h3 className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider">
                      {section.title}
                    </h3>
                  )}
                  <div className="mt-2 space-y-1">
                    {section.items ? (
                      section.items.map((item, idx) => (
                        <NavLink
                          key={idx}
                          to={item.path}
                          className={({ isActive }) =>
                            `group flex items-center px-3 py-2 text-sm font-medium rounded-md ${
                              isActive
                                ? 'bg-purple-50 text-purple-600'
                                : 'text-gray-700 hover:bg-purple-50 hover:text-purple-600'
                            }`
                          }
                        >
                          <Icon name={item.icon} className="mr-3 h-5 w-5" />
                          {item.title}
                        </NavLink>
                      ))
                    ) : (
                      <NavLink
                        to={section.path}
                        className={({ isActive }) =>
                          `group flex items-center px-3 py-2 text-sm font-medium rounded-md ${
                            isActive
                              ? 'bg-purple-50 text-purple-600'
                              : 'text-gray-700 hover:bg-purple-50 hover:text-purple-600'
                          }`
                        }
                      >
                        <Icon name={section.icon} className="mr-3 h-5 w-5" />
                        {section.title}
                      </NavLink>
                    )}
                  </div>
                </div>
              ))}
            </nav>
          </div>
        </div>
      </div>

      {/* Overlay */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-30 md:hidden"
          onClick={() => setIsOpen(false)}
        ></div>
      )}
    </>
  );
};

export default AdminSidebar;
