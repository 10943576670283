import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import { FaSearch, FaSpinner, FaEnvelope, FaTrash, FaUserEdit } from 'react-icons/fa';
import AdminLayout from '../components/AdminLayout';

const UserInfo = () => {
  // Fake data - replace this with API data later
  const fakeUsers = [
    {
      _id: "1",
      name: "John Doe",
      email: "john@example.com",
      paymentStatus: "paid",
      joinDate: "2024-01-15",
      phone: "+1 234-567-8900"
    },
    {
      _id: "2",
      name: "Jane Smith",
      email: "jane@example.com",
      paymentStatus: "unpaid",
      joinDate: "2024-02-01",
      phone: "+1 234-567-8901"
    },
    {
      _id: "3",
      name: "Mike Johnson",
      email: "mike@example.com",
      paymentStatus: "paid",
      joinDate: "2024-02-15",
      phone: "+1 234-567-8902"
    },
    {
      _id: "4",
      name: "Sarah Williams",
      email: "sarah@example.com",
      paymentStatus: "unpaid",
      joinDate: "2024-03-01",
      phone: "+1 234-567-8903"
    },
    {
      _id: "5",
      name: "David Brown",
      email: "david@example.com",
      paymentStatus: "paid",
      joinDate: "2024-03-10",
      phone: "+1 234-567-8904"
    },{
      _id: "6",
      name: "John Doe",
      email: "john@example.com",
      paymentStatus: "paid",
      joinDate: "2024-01-15",
      phone: "+1 234-567-8900"
    },
    {
      _id: "7",
      name: "Jane Smith",
      email: "jane@example.com",
      paymentStatus: "unpaid",
      joinDate: "2024-02-01",
      phone: "+1 234-567-8901"
    },
    {
      _id: "8",
      name: "Mike Johnson",
      email: "mike@example.com",
      paymentStatus: "paid",
      joinDate: "2024-02-15",
      phone: "+1 234-567-8902"
    },
    {
      _id: "9",
      name: "Sarah Williams",
      email: "sarah@example.com",
      paymentStatus: "unpaid",
      joinDate: "2024-03-01",
      phone: "+1 234-567-8903"
    },
    {
      _id: "10",
      name: "David Brown",
      email: "david@example.com",
      paymentStatus: "paid",
      joinDate: "2024-03-10",
      phone: "+1 234-567-8904"
    },{
      _id: "11",
      name: "John Doe",
      email: "john@example.com",
      paymentStatus: "paid",
      joinDate: "2024-01-15",
      phone: "+1 234-567-8900"
    },
    {
      _id: "12",
      name: "Jane Smith",
      email: "jane@example.com",
      paymentStatus: "unpaid",
      joinDate: "2024-02-01",
      phone: "+1 234-567-8901"
    },
    {
      _id: "13",
      name: "Mike Johnson",
      email: "mike@example.com",
      paymentStatus: "paid",
      joinDate: "2024-02-15",
      phone: "+1 234-567-8902"
    },
    {
      _id: "14",
      name: "Sarah Williams",
      email: "sarah@example.com",
      paymentStatus: "unpaid",
      joinDate: "2024-03-01",
      phone: "+1 234-567-8903"
    },
    {
      _id: "15",
      name: "David Brown",
      email: "david@example.com",
      paymentStatus: "paid",
      joinDate: "2024-03-10",
      phone: "+1 234-567-8904"
    }
  ];

  // States
  const [users] = useState(fakeUsers);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const tableContainerRef = useRef(null);
  const cardContainerRef = useRef(null);
  const [statusFilter, setStatusFilter] = useState('all');

  const ITEMS_PER_PAGE = {
    MOBILE: 4,
    DESKTOP: 10
  };

  // Filter users
  const filteredUsers = useMemo(() => {
    return users.filter(user => {
      const matchesSearch = 
        user.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email?.toLowerCase().includes(searchTerm.toLowerCase());
      
      const matchesStatus = 
        statusFilter === 'all' || 
        user.paymentStatus === statusFilter;
      
      return matchesSearch && matchesStatus;
    });
  }, [users, searchTerm, statusFilter]);

  // Get paginated data
  const getPaginatedData = useCallback(() => {
    const isMobile = window.innerWidth < 768;
    const itemsPerPage = isMobile ? ITEMS_PER_PAGE.MOBILE : ITEMS_PER_PAGE.DESKTOP;
    const endIndex = page * itemsPerPage;
    return filteredUsers.slice(0, endIndex);
  }, [filteredUsers, page]);

  // Handle scroll
  const handleScroll = useCallback((e) => {
    if (loading || !hasMore) return;

    const { scrollTop, scrollHeight, clientHeight } = e.target;
    
    if (scrollHeight - scrollTop - clientHeight < 100) {
      setLoading(true);
      setTimeout(() => {
        setPage(prev => prev + 1);
        setLoading(false);
      }, 500);
    }
  }, [loading, hasMore]);

  // Update hasMore when data changes
  useEffect(() => {
    setHasMore(getPaginatedData().length < filteredUsers.length);
  }, [getPaginatedData, filteredUsers.length]);

  // Reset page when search changes
  useEffect(() => {
    setPage(1);
  }, [searchTerm, statusFilter]);

  const handleDeleteUser = (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      // TODO: Add API delete call here
      console.log("Delete user:", userId);
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <AdminLayout>
      {/* Page Header */}
      <div className="mb-6">
        <h1 className="text-2xl font-bold text-gray-900">User Information</h1>
      </div>

      {/* Search and Filter Bar */}
      <div className="mb-6">
        <div className="flex flex-col md:flex-row items-center gap-4">
          {/* Search Input */}
          <div className="relative w-full md:max-w-md">
            <FaSearch className="absolute left-3 top-3 text-gray-400" />
            <input
              type="text"
              placeholder="Search users..."
              className="w-full pl-10 pr-4 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-purple-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          {/* Status Filter */}
          <div className="w-full md:w-48">
            <select
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              className="w-full px-4 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-purple-500 bg-white"
            >
              <option value="all">All Status</option>
              <option value="paid">Paid</option>
              <option value="unpaid">Unpaid</option>
            </select>
          </div>

          {/* Stats Summary */}
          <div className="hidden md:flex gap-4 ml-auto">
            <div className="text-sm">
              <span className="text-base font-semibold  text-gray-800">Total Users:</span>
              <span className="ml-2 font-medium">{filteredUsers.length}</span>
            </div>
            <div className="text-sm">
              <span className="text-base font-semibold  text-gray-800">Paid:</span>
              <span className="ml-2 font-medium text-green-600">
                {filteredUsers.filter(user => user.paymentStatus === 'paid').length}
              </span>
            </div>
            <div className="text-sm">
              <span className="text-base font-semibold  text-gray-800">Unpaid:</span>
              <span className="ml-2 font-medium text-red-600">
                {filteredUsers.filter(user => user.paymentStatus === 'unpaid').length}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Content Area */}
      <div className="bg-white rounded-lg shadow-sm">
        {/* Mobile Card View */}
        <div className="block md:hidden">
          <div 
            className="bg-gray-50 rounded-lg p-4"
            style={{ height: 'calc(100vh - 220px)' }}
          >
            <div 
              ref={cardContainerRef}
              className="h-full overflow-y-auto space-y-4 pr-2"
              onScroll={handleScroll}
            >
              {getPaginatedData().map((user) => (
                <div key={user._id} className="bg-white rounded-lg shadow-md p-4">
                  <div className="flex items-center space-x-4 mb-3">
                    <img
                      className="h-12 w-12 rounded-full"
                      src={`https://ui-avatars.com/api/?name=${user.name}&background=random`}
                      alt={user.name}
                    />
                    <div>
                      <h3 className="font-medium text-gray-900">{user.name}</h3>
                      <p className="text-sm text-gray-500">{user.email}</p>
                    </div>
                  </div>
                  
                  <div className="space-y-2 mb-4">
                    <div className="flex justify-between text-sm">
                      <span className="text-gray-500">Phone:</span>
                      <span className="text-gray-900">{user.phone}</span>
                    </div>
                    <div className="flex justify-between text-sm">
                      <span className="text-gray-500">Join Date:</span>
                      <span className="text-gray-900">{formatDate(user.joinDate)}</span>
                    </div>
                    <div className="flex justify-between text-sm items-center">
                      <span className="text-gray-500">Status:</span>
                      <span className={`px-2 py-1 text-xs rounded-full ${
                        user.paymentStatus === 'paid' 
                          ? 'bg-green-100 text-green-800' 
                          : 'bg-red-100 text-red-800'
                      }`}>
                        {user.paymentStatus === 'paid' ? 'Paid' : 'Unpaid'}
                      </span>
                    </div>
                  </div>

                  <div className="flex justify-end space-x-3 pt-3 border-t">
                    <button className="p-2 text-purple-600 hover:text-purple-900">
                      <FaUserEdit className="w-5 h-5" />
                    </button>
                    <a
                      href={`mailto:${user.email}`}
                      className="p-2 text-blue-600 hover:text-blue-900"
                    >
                      <FaEnvelope className="w-5 h-5" />
                    </a>
                    <button
                      onClick={() => handleDeleteUser(user._id)}
                      className="p-2 text-red-600 hover:text-red-900"
                    >
                      <FaTrash className="w-5 h-5" />
                    </button>
                  </div>
                </div>
              ))}

              {/* Loading Indicator */}
              {loading && (
                <div className="flex justify-center py-4">
                  <div className="animate-spin rounded-full h-8 w-8 border-2 border-purple-500 border-t-transparent"></div>
                </div>
              )}

              {/* End of Content Message */}
              {!hasMore && getPaginatedData().length > 0 && (
                <div className="text-center py-4 text-gray-500">
                  No more users to load
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Desktop Table View */}
        <div className="hidden md:block bg-white rounded-lg shadow overflow-hidden">
          <div 
            ref={tableContainerRef}
            className="overflow-auto max-h-[calc(100vh-250px)]"
            onScroll={handleScroll}
          >
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 sticky top-0 z-10">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">User Details</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Contact</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Status</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Join Date</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {getPaginatedData().map((user) => (
                  <tr key={user._id} className="hover:bg-gray-50">
                    <td className="px-6 py-4">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10">
                          <img
                            className="h-10 w-10 rounded-full"
                            src={`https://ui-avatars.com/api/?name=${user.name}&background=random`}
                            alt={user.name}
                          />
                        </div>
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">{user.name}</div>
                          <div className="text-sm text-gray-500">ID: {user._id}</div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-900">{user.email}</div>
                      <div className="text-sm text-gray-500">{user.phone}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        user.paymentStatus === 'paid' 
                          ? 'bg-green-100 text-green-800' 
                          : 'bg-red-100 text-red-800'
                      }`}>
                        {user.paymentStatus === 'paid' ? 'Paid' : 'Unpaid'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">
                        {formatDate(user.joinDate)}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex space-x-3">
                        <button
                          className="text-purple-600 hover:text-purple-900"
                          title="Edit User"
                        >
                          <FaUserEdit className="w-5 h-5" />
                        </button>
                        <a
                          href={`mailto:${user.email}`}
                          className="text-blue-600 hover:text-blue-900"
                          title="Send Email"
                        >
                          <FaEnvelope className="w-5 h-5" />
                        </a>
                        <button
                          onClick={() => handleDeleteUser(user._id)}
                          className="text-red-600 hover:text-red-900"
                          title="Delete User"
                        >
                          <FaTrash className="w-5 h-5" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Loading Indicator */}
            {loading && (
              <div className="flex justify-center py-4 bg-white border-t">
                <div className="animate-spin rounded-full h-8 w-8 border-2 border-purple-500 border-t-transparent"></div>
              </div>
            )}

            {/* End of Content Message */}
            {!hasMore && getPaginatedData().length > 0 && (
              <div className="text-center py-4 text-gray-500 border-t">
                No more users to load
              </div>
            )}
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default UserInfo;
