// src/adminpanel/AdminDashboard.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './components/Header'; // Reusing the Header component
import AdminSidebar from './components/Sidebar';
import DashboardCard from './components/DashboardCard';
import RecentOrdersList from './components/RecentOrdersList';
import RecentMessagesList from './components/RecentMessagesList';

const AdminDashboard = () => {
  const [stats, setStats] = useState({
    totalUsers: 0,
    totalOrders: 0,
    pendingEvaluations: 0,
    newMessages: 0
  });

  useEffect(() => {
    // Fetch dashboard statistics
    const fetchStats = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_LOCALHOST}dashboard/stats`);
        setStats(response.data);
      } catch (error) {
        console.error("Error fetching stats:", error);
      }
    };
    fetchStats();
  }, []);

  return (
    <div className="flex flex-col md:flex-row min-h-screen bg-violet-50">
      <AdminSidebar />
      
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header />
        
        <main className="flex-1 overflow-y-auto bg-violet-50 p-4 md:p-6">
          {/* Dashboard Overview */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-6 mb-6 md:mb-8">
            {/* Stats Cards */}
            <DashboardCard
              title="Total Users"
              value={stats.totalUsers}
              icon="users"
              color="blue"
            />
            <DashboardCard
              title="Total Orders"
              value={stats.totalOrders}
              icon="shopping-cart"
              color="green"
            />
            <DashboardCard
              title="Pending Evaluations"
              value={stats.pendingEvaluations}
              icon="document"
              color="yellow"
            />
            <DashboardCard
              title="New Messages"
              value={stats.newMessages}
              icon="chat"
              color="purple"
            />
          </div>

          {/* Recent Activity Section */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-8">
            {/* Recent Orders */}
            <div className="bg-white rounded-lg shadow-sm p-4 md:p-6 overflow-x-auto">
              <h3 className="text-base md:text-lg font-semibold mb-4">Recent Orders</h3>
              <RecentOrdersList />
            </div>

            {/* Recent Messages */}
            <div className="bg-white rounded-lg shadow-sm p-4 md:p-6 overflow-x-auto">
              <h3 className="text-base md:text-lg font-semibold mb-4">Recent Messages</h3>
              <RecentMessagesList />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default AdminDashboard;
