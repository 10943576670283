import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import { FaSearch, FaSpinner, FaEnvelope, FaTrash } from 'react-icons/fa';
import AdminLayout from '../components/AdminLayout';

const ContactUs = () => {
  // Dummy data
  const dummyContacts = [
    {
      _id: "1",
      name: "John Smith",
      email: "john.smith@example.com",
      message: "I would like to know more about your resume writing services. Please contact me at your earliest convenience.",
      createdAt: "2024-03-15T10:30:00"
    },
    {
      _id: "2",
      name: "Sarah Johnson",
      email: "sarah.j@example.com",
      message: "Hi, I'm interested in the premium package. Could you provide more details about the turnaround time?",
      createdAt: "2024-03-14T15:45:00"
    },
    {
      _id: "3",
      name: "Michael Brown",
      email: "michael.b@example.com",
      message: "Need help with my technical resume. Looking forward to hearing from you soon.",
      createdAt: "2024-03-14T09:20:00"
    },
    {
      _id: "4",
      name: "Emma Wilson",
      email: "emma.w@example.com",
      message: "Question about the cover letter service. Do you offer industry-specific templates?",
      createdAt: "2024-03-13T16:15:00"
    },
    {
      _id: "5",
      name: "David Lee",
      email: "david.lee@example.com",
      message: "Looking for urgent resume review. What's the fastest turnaround time possible?",
      createdAt: "2024-03-13T11:30:00"
    },{
      _id: "6",
      name: "John Smith",
      email: "john.smith@example.com",
      message: "I would like to know more about your resume writing services. Please contact me at your earliest convenience.",
      createdAt: "2024-03-15T10:30:00"
    },
    {
      _id: "7",
      name: "Sarah Johnson",
      email: "sarah.j@example.com",
      message: "Hi, I'm interested in the premium package. Could you provide more details about the turnaround time?",
      createdAt: "2024-03-14T15:45:00"
    },
    {
      _id: "8",
      name: "Michael Brown",
      email: "michael.b@example.com",
      message: "Need help with my technical resume. Looking forward to hearing from you soon.",
      createdAt: "2024-03-14T09:20:00"
    },
    {
      _id: "9",
      name: "Emma Wilson",
      email: "emma.w@example.com",
      message: "Question about the cover letter service. Do you offer industry-specific templates?",
      createdAt: "2024-03-13T16:15:00"
    },
    {
      _id: "10",
      name: "David Lee",
      email: "david.lee@example.com",
      message: "Looking for urgent resume review. What's the fastest turnaround time possible?",
      createdAt: "2024-03-13T11:30:00"
    },{
      _id: "11",
      name: "John Smith",
      email: "john.smith@example.com",
      message: "I would like to know more about your resume writing services. Please contact me at your earliest convenience.",
      createdAt: "2024-03-15T10:30:00"
    },
    {
      _id: "12",
      name: "Sarah Johnson",
      email: "sarah.j@example.com",
      message: "Hi, I'm interested in the premium package. Could you provide more details about the turnaround time?",
      createdAt: "2024-03-14T15:45:00"
    },
    {
      _id: "13",
      name: "Michael Brown",
      email: "michael.b@example.com",
      message: "Need help with my technical resume. Looking forward to hearing from you soon.",
      createdAt: "2024-03-14T09:20:00"
    },
    {
      _id: "14",
      name: "Emma Wilson",
      email: "emma.w@example.com",
      message: "Question about the cover letter service. Do you offer industry-specific templates?",
      createdAt: "2024-03-13T16:15:00"
    },
    {
      _id: "15",
      name: "David Lee",
      email: "david.lee@example.com",
      message: "Looking for urgent resume review. What's the fastest turnaround time possible?",
      createdAt: "2024-03-13T11:30:00"
    }
  ];

  // States
  const [contacts] = useState(dummyContacts);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const tableContainerRef = useRef(null); // Reference for table container
  const [mobileLoading, setMobileLoading] = useState(false);
  const [mobilePage, setMobilePage] = useState(1);
  const [mobileHasMore, setMobileHasMore] = useState(true);
  const cardContainerRef = useRef(null);

  // Add new states for date filtering
  const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: ''
  });

  const ITEMS_PER_PAGE = {
    MOBILE: 4,
    DESKTOP: 10
  };

  // Update the filteredContacts to include date filtering
  const filteredContacts = useMemo(() => {
    return contacts.filter(contact => {
      const matchesSearch = 
        contact.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        contact.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        contact.message?.toLowerCase().includes(searchTerm.toLowerCase());

      // Date filtering
      const contactDate = new Date(contact.createdAt);
      const startDate = dateRange.startDate ? new Date(dateRange.startDate) : null;
      const endDate = dateRange.endDate ? new Date(dateRange.endDate) : null;

      const matchesDateRange = 
        (!startDate || contactDate >= startDate) &&
        (!endDate || contactDate <= endDate);

      return matchesSearch && matchesDateRange;
    });
  }, [contacts, searchTerm, dateRange]);

  // Get paginated data for table
  const getPaginatedData = useCallback(() => {
    const endIndex = page * ITEMS_PER_PAGE.DESKTOP;
    return filteredContacts.slice(0, endIndex);
  }, [filteredContacts, page]);

  // Handle table scroll
  const handleTableScroll = useCallback(() => {
    if (!tableContainerRef.current || loading || !hasMore) return;

    const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current;
    
    // Check if scrolled near bottom (within 50px)
    if (scrollHeight - scrollTop - clientHeight < 50) {
      setLoading(true);
      // Simulate API call
      setTimeout(() => {
        setPage(prev => prev + 1);
        setLoading(false);
      }, 500);
    }
  }, [loading, hasMore]);

  // Update hasMore when data changes
  useEffect(() => {
    setHasMore(getPaginatedData().length < filteredContacts.length);
  }, [getPaginatedData, filteredContacts.length]);

  // Reset pagination when search changes
  useEffect(() => {
    setPage(1);
  }, [searchTerm]);

  // Add scroll listener to table container
  useEffect(() => {
    const tableContainer = tableContainerRef.current;
    if (tableContainer) {
      tableContainer.addEventListener('scroll', handleTableScroll);
      return () => tableContainer.removeEventListener('scroll', handleTableScroll);
    }
  }, [handleTableScroll]);

  // Get paginated data for mobile cards
  const getMobileData = useCallback(() => {
    const endIndex = mobilePage * ITEMS_PER_PAGE.MOBILE;
    return filteredContacts.slice(0, endIndex);
  }, [filteredContacts, mobilePage]);

  // Handle card container scroll
  const handleCardScroll = useCallback((e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    
    // Check if we're near bottom (within 100px)
    if (scrollHeight - scrollTop - clientHeight < 100) {
      if (!mobileLoading && mobileHasMore) {
        setMobileLoading(true);
        // Simulate API call
        setTimeout(() => {
          setMobilePage(prev => prev + 1);
          setMobileLoading(false);
        }, 500);
      }
    }
  }, [mobileLoading, mobileHasMore]);

  // Update mobile hasMore
  useEffect(() => {
    setMobileHasMore(getMobileData().length < filteredContacts.length);
  }, [getMobileData, filteredContacts.length]);

  // Reset mobile pagination on search
  useEffect(() => {
    setMobilePage(1);
  }, [searchTerm]);

  // Add scroll listener for mobile cards
  useEffect(() => {
    const cardContainer = cardContainerRef.current;
    if (cardContainer && window.innerWidth < 768) {
      cardContainer.addEventListener('scroll', handleCardScroll);
      return () => cardContainer.removeEventListener('scroll', handleCardScroll);
    }
  }, [handleCardScroll]);

  // Format date helper
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  // Handle delete
  const handleDelete = useCallback((id) => {
    // TODO: Add API delete call here
    console.log("Delete contact:", id);
  }, []);

  // Handle date filter reset
  const handleResetDateFilter = () => {
    setDateRange({
      startDate: '',
      endDate: ''
    });
  };

  return (
    <AdminLayout>
      {/* Page Header */}
      <div className="mb-6">
        <h1 className="text-2xl font-bold text-gray-900">Contact Form Submissions</h1>
      </div>

      {/* Search and Filter Bar */}
      <div className="mb-6 flex flex-col md:flex-row gap-4">
        {/* Search Input */}
        <div className="relative flex-1">
          <FaSearch className="absolute left-3 top-3 text-gray-400" />
          <input
            type="text"
            placeholder="Search submissions..."
            className="w-full pl-10 pr-4 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-purple-500"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {/* Date Filters */}
        <div className="flex flex-wrap gap-2 md:gap-4 items-center">
          <div className="flex items-center gap-2">
            <input
              type="date"
              value={dateRange.startDate}
              onChange={(e) => setDateRange(prev => ({ ...prev, startDate: e.target.value }))}
              className="px-3 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-purple-500"
            />
            <span className="text-gray-500">to</span>
            <input
              type="date"
              value={dateRange.endDate}
              onChange={(e) => setDateRange(prev => ({ ...prev, endDate: e.target.value }))}
              className="px-3 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-purple-500"
            />
          </div>

          {/* Reset Filter Button */}
          {(dateRange.startDate || dateRange.endDate) && (
            <button
              onClick={handleResetDateFilter}
              className="px-3 py-2 text-sm text-gray-600 hover:text-gray-900 transition-colors"
            >
              Clear Dates
            </button>
          )}
        </div>
      </div>

      {/* Content Area */}
      <div className="grid grid-cols-1 gap-4 md:gap-6">
        {/* Mobile View */}
        <div className="block md:hidden">
          <div 
            className="bg-gray-50 rounded-lg p-4" // Container for cards with background
            style={{ height: 'calc(100vh - 180px)' }} // Fixed height for the container
          >
            <div 
              ref={cardContainerRef}
              className="h-full overflow-y-auto space-y-4 pr-2" // Scrollable area for cards
              onScroll={handleCardScroll}
            >
              {getMobileData().map((contact, index) => (
                <div
                  key={contact._id}
                  className="bg-white rounded-lg shadow-md p-4 transform transition-all duration-300 hover:shadow-lg"
                >
                  {/* Card Header */}
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="font-medium text-gray-900">{contact.name}</h3>
                      <p className="text-sm text-gray-500">{contact.email}</p>
                    </div>
                    <div className="flex space-x-2">
                      <a
                        href={`mailto:${contact.email}`}
                        className="p-2 text-blue-600 hover:text-blue-900 transition-colors"
                      >
                        <FaEnvelope className="w-5 h-5" />
                      </a>
                      <button
                        onClick={() => handleDelete(contact._id)}
                        className="p-2 text-red-600 hover:text-red-900 transition-colors"
                      >
                        <FaTrash className="w-5 h-5" />
                      </button>
                    </div>
                  </div>

                  {/* Card Body */}
                  <div className="mt-3">
                    <p className="text-sm text-gray-600 line-clamp-3">
                      {contact.message}
                    </p>
                  </div>

                  {/* Card Footer */}
                  <div className="mt-3 pt-3 border-t border-gray-100">
                    <p className="text-xs text-gray-400">
                      {formatDate(contact.createdAt)}
                    </p>
                  </div>
                </div>
              ))}

              {/* Loading Indicator */}
              {mobileLoading && (
                <div className="flex justify-center py-4">
                  <FaSpinner className="w-6 h-6 text-purple-500 animate-spin" />
                </div>
              )}

              {/* End of Content Message */}
              {!mobileHasMore && getMobileData().length > 0 && (
                <div className="text-center py-4 text-gray-500 text-sm">
                  No more submissions to load
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Desktop Table */}
        <div className="hidden md:block bg-white rounded-lg shadow-sm">
          <div 
            ref={tableContainerRef}
            className="overflow-auto max-h-[calc(100vh-250px)]" // Fixed height for scroll
          >
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 sticky top-0 z-10">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </th>
                  <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Email
                  </th>
                  <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Message
                  </th>
                  <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date
                  </th>
                  <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {getPaginatedData().map((contact) => (
                  <tr key={contact._id} className="hover:bg-gray-50">
                    <td className="px-4 md:px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">{contact.name}</div>
                    </td>
                    <td className="px-4 md:px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">{contact.email}</div>
                    </td>
                    <td className="px-4 md:px-6 py-4">
                      <div className="text-sm text-gray-500 max-w-xs truncate">
                        {contact.message}
                      </div>
                    </td>
                    <td className="px-4 md:px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">
                        {formatDate(contact.createdAt)}
                      </div>
                    </td>
                    <td className="px-4 md:px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <div className="flex space-x-3">
                        <a
                          href={`mailto:${contact.email}`}
                          className="text-blue-600 hover:text-blue-900 transition-colors"
                          title="Send Email"
                        >
                          <FaEnvelope className="w-5 h-5" />
                        </a>
                        <button
                          onClick={() => handleDelete(contact._id)}
                          className="text-red-600 hover:text-red-900 transition-colors"
                          title="Delete"
                        >
                          <FaTrash className="w-5 h-5" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Loading Indicator inside table */}
            {loading && (
              <div className="flex justify-center py-4 bg-white border-t">
                <FaSpinner className="w-6 h-6 text-purple-500 animate-spin" />
              </div>
            )}

            {/* End of Content Indicator */}
            {!hasMore && getPaginatedData().length > 0 && (
              <div className="text-center py-4 text-gray-500 border-t">
                No more submissions to load
              </div>
            )}
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default ContactUs;
