import React, { useEffect, useState } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import Footer from '../../components/footer/Footer';
import Navbar from '../../components/navbar/Navbar';

function ResumeServices() {
  // Ensure the correct environment variable is used
  const api = process.env.REACT_APP_LOCALHOST;
  console.log("your env",api); // Check the logged output

  const [data, setData] = useState([]);

  useEffect(() => {
    // Fetching from the correct endpoint
    fetch(`${api}pricing`)
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error('Error:', error));
  }, [api]); // Include api as a dependency

  return (
    <>
      <Navbar />
      <div className="bg-violet-50 py-8 pt-28">
        <div className="max-w-6xl mx-auto text-center">
          <h1 className="text-4xl text-indigo-400 font-bold mb-4">Service Pricing and Packages</h1>
          <p className="mx-auto text-lg max-w-3xl">
            We are committed to maintaining a five-star rating each month from our valued clients as part of our company policy, and we have achieved this benchmark throughout our history.
          </p>
        </div>
        <div className="py-16">
          {/* Container with grey background */}
          <div className="bg-gray-200 py-8 px-6 md:px-12 lg:px-24 mx-auto w-[90%] rounded-3xl">
            <div className="max-w-6xl mx-auto text-center">
              <h2 className="text-3xl text-indigo-400 font-semibold mb-4">
                Explore Our Pricing Options and Service Bundles
              </h2>
              <p className="text-lg text-gray-800 max-w-2xl mx-auto">
                We will revise your documents at no additional cost if you do not secure any interviews within two months.
              </p>
            </div>

            {/* Pricing Cards */}
            <div className="py-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 max-w-6xl mx-auto">
              {data.map((plan, index) => (
                <div key={index} className="bg-gray-50 flex flex-col rounded-2xl shadow-lg p-6 justify-between">
                  <div>
                    <h3 className="text-4xl text-center font-bold text-gray-800">{plan.label}</h3>
                    <h4 className="text-xl text-center font-semibold text-gray-800">{plan.title}</h4>
                    <p className="mt-2 text-center text-lg text-gray-600">{plan.subtitle}</p>
                    <p className="mt-6 text-5xl text-center font-extrabold text-gray-900">{plan.price}</p>
                    <div className="flex justify-center mt-4">
                      <button className="px-16 py-3 bg-violet-200 font-bold text-lg rounded-full shadow-md hover:bg-violet-300 transition duration-300 ease-in-out">
                        {plan.buttonText}
                      </button>
                    </div>
                    <ul className="mt-6 space-y-4 text-left text-lg">
                      {plan.features.map((feature, i) => (
                        <li key={i} className="flex items-center text-gray-700">
                          {feature.included ? (
                            <FaCheck className="text-green-500 mr-2" />
                          ) : (
                            <FaTimes className="text-red-500 mr-2" />
                          )}
                          <span className='pl-2'>{feature.name}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="border-t-4 border-dotted border-gray-400">
                    <p className="mt-4 text-center font-bold">{plan.footerText}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="max-w-6xl py-8 mx-auto text-center">
          <p className="mx-auto font-semibold text-xl max-w-3xl">
            Want to gift it to someone special or need a quote for military, federal, or academic resumes? Don’t hesitate to <a href="/contact" className="text-blue-600 ">contact us</a> for more information or assistance.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ResumeServices;
