// Questionnaire.js
import React from 'react';
import UserLayout from '../components/UserLayout';

const Questionnaire = () => {
  return (
    <UserLayout>
      <div className="max-w-4xl mx-auto">
        <h2 className="text-3xl font-bold text-purple-600 text-center mb-8">
          Questionnaire
        </h2>

        <div className="bg-white p-6 rounded-lg shadow-lg">
          {/* File Upload Section */}
          <div className="mb-8 text-center">
            <p className="text-gray-700 font-semibold mb-2">
              Submit your most recent resume by dragging it here or uploading it directly.
            </p>
            <div className="w-full bg-gray-50 border-dashed border-2 border-gray-300 p-6 rounded-lg hover:shadow-md cursor-pointer transition duration-300">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 16v1a2 2 0 002 2h12a2 2 0 002-2v-1M16 8l-4-4m0 0L8 8m4-4v12"
                />
              </svg>
              <p className="mt-2 text-sm text-gray-600">Click or drag file to upload</p>
            </div>
          </div>

          {/* Links Section */}
          <div className="mb-8">
            <p className="font-semibold text-gray-700 mb-4">
              Please provide links to at least two job openings you are interested in applying for.
            </p>
            <div className="space-y-4">
              <input
                type="text"
                placeholder="Link 1: Paste job posting URL here"
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
              />
              <input
                type="text"
                placeholder="Link 2: Paste job posting URL here"
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
              />
            </div>
          </div>

          {/* Questionnaire Section */}
          <div className="space-y-6">
            <div>
              <p className="font-semibold text-gray-700 mb-2">
                What are your biggest concerns with your current resume?
              </p>
              <textarea
                className="w-full h-24 px-4 py-2 border rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                placeholder="Please describe your concerns..."
              />
            </div>

            <div>
              <p className="font-semibold text-gray-700 mb-2">
                What do you consider the strongest aspect of your current resume?
              </p>
              <textarea
                className="w-full h-24 px-4 py-2 border rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                placeholder="Share your resume's strengths..."
              />
            </div>

            <div>
              <p className="font-semibold text-gray-700 mb-2">
                Are there any obstacles you believe are hindering your ability to secure a new position?
              </p>
              <textarea
                className="w-full h-24 px-4 py-2 border rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                placeholder="Describe any challenges you're facing..."
              />
            </div>

            {/* Submit Button */}
            <div className="flex justify-end mt-6">
              <button
                type="submit"
                className="px-6 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 transition duration-300"
              >
                Submit Questionnaire
              </button>
            </div>
          </div>
        </div>
      </div>
    </UserLayout>
  );
};

export default Questionnaire;
