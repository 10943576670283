import React, { useState } from 'react';
import { FaSearch, FaSpinner, FaEnvelope, FaDownload, FaEye } from 'react-icons/fa';
import AdminLayout from '../components/AdminLayout';

const Questionnaire = () => {
  // Fake questionnaire data
  const dummyQuestionnaires = [
    {
      _id: "1",
      name: "John Smith",
      email: "john.smith@example.com",
      createdAt: "2024-03-15T10:30:00",
      resumeFile: "john-smith-resume.pdf",
      jobLink1: "https://example.com/job1",
      jobLink2: "https://example.com/job2",
      concerns: "My resume lacks quantifiable achievements and seems too generic. I'm worried it doesn't effectively showcase my leadership experience.",
      strengths: "Strong educational background and consistent career progression in software development.",
      obstacles: "Difficulty in translating technical achievements into business value. Need help highlighting transferable skills."
    },
    {
      _id: "2",
      name: "Sarah Johnson",
      email: "sarah.j@example.com",
      createdAt: "2024-03-14T15:45:00",
      resumeFile: "sarah-resume.pdf",
      jobLink1: "https://example.com/job3",
      jobLink2: "https://example.com/job4",
      concerns: "Resume is too long and doesn't focus on relevant experience. Need help with formatting and organization.",
      strengths: "Diverse project experience and strong client relationship management skills.",
      obstacles: "Career gap due to personal development sabbatical. Need to present this positively."
    },
    {
      _id: "3",
      name: "Michael Brown",
      email: "michael.b@example.com",
      createdAt: "2024-03-13T09:20:00",
      resumeFile: "michael-resume.pdf",
      jobLink1: "https://example.com/job5",
      jobLink2: "https://example.com/job6",
      concerns: "Not sure if my skills align well with the industry I'm targeting. Resume might be too technical.",
      strengths: "Extensive technical expertise and proven track record of successful project deliveries.",
      obstacles: "Transitioning to a new industry. Need help highlighting transferable skills."
    },
    {
      _id: "4",
      name: "Emma Wilson",
      email: "emma.w@example.com",
      createdAt: "2024-03-12T14:15:00",
      resumeFile: "emma-resume.pdf",
      jobLink1: "https://example.com/job7",
      jobLink2: "https://example.com/job8",
      concerns: "Resume doesn't reflect my career progression well. Need to highlight management experience better.",
      strengths: "Strong leadership skills and successful team management experience.",
      obstacles: "Looking to transition to a more senior role. Need help positioning experience appropriately."
    },
    {
      _id: "5",
      name: "David Lee",
      email: "david.l@example.com",
      createdAt: "2024-03-11T11:30:00",
      resumeFile: "david-resume.pdf",
      jobLink1: "https://example.com/job9",
      jobLink2: "https://example.com/job10",
      concerns: "Resume feels outdated and doesn't showcase recent certifications effectively.",
      strengths: "Recent professional certifications and hands-on experience with modern technologies.",
      obstacles: "Need to better align resume with current industry trends and requirements."
    }
  ];

  // Add this line for files path
  const files = "https://example.com/files/"; // Replace with your actual files path when implementing API

  const [questionnaires] = useState(dummyQuestionnaires);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading] = useState(false);
  const [selectedResponse, setSelectedResponse] = useState(null);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const filteredResponses = questionnaires.filter(response =>
    response.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    response.email?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <AdminLayout>
      {/* Header */}
      <div className="mb-6">
        <h1 className="text-2xl font-bold text-gray-900">Questionnaire Responses</h1>
        <p className="mt-2 text-gray-600">View and manage user questionnaire submissions</p>
      </div>

      {/* Search Bar */}
      <div className="mb-6">
        <div className="relative w-full md:max-w-md">
          <FaSearch className="absolute left-3 top-3 text-gray-400" />
          <input
            type="text"
            placeholder="Search by name or email..."
            className="w-full pl-10 pr-4 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-purple-500"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      {/* Content Area */}
      <div>
        {/* Mobile View */}
        <div className="block md:hidden space-y-4">
          {filteredResponses.map((response) => (
            <div 
              key={response._id} 
              className="bg-white rounded-lg shadow-sm p-4"
              onClick={() => setSelectedResponse(response)}
            >
              <div className="mb-4">
                <h3 className="font-medium text-gray-900">{response.name}</h3>
                <p className="text-sm text-gray-500">{response.email}</p>
                <p className="text-xs text-gray-400 mt-1">{formatDate(response.createdAt)}</p>
              </div>

              <div className="space-y-4">
                <div>
                  <h4 className="text-sm font-medium text-gray-700">Concerns:</h4>
                  <p className="text-sm text-gray-600 line-clamp-2">{response.concerns}</p>
                </div>
                <div className="flex justify-end">
                  <a
                    href={`mailto:${response.email}`}
                    className="px-3 py-1.5 bg-purple-500 text-white rounded-lg text-sm"
                  >
                    Contact
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Tablet/Desktop View */}
        <div className="hidden md:flex gap-6">
          {/* Responses List */}
          <div className="w-1/3">
            <div className="bg-white rounded-lg shadow-sm overflow-hidden">
              <div className="p-4 border-b">
                <h2 className="text-lg font-semibold text-gray-900">Submissions</h2>
              </div>
              <div className="overflow-y-auto" style={{ maxHeight: 'calc(100vh - 280px)' }}>
                {isLoading ? (
                  <div className="p-4 flex justify-center">
                    <FaSpinner className="w-6 h-6 text-purple-500 animate-spin" />
                  </div>
                ) : filteredResponses.length === 0 ? (
                  <div className="p-4 text-center text-gray-500">
                    No responses found
                  </div>
                ) : (
                  <div className="divide-y divide-gray-200">
                    {filteredResponses.map((response) => (
                      <div
                        key={response._id}
                        className={`p-4 cursor-pointer hover:bg-gray-50 ${
                          selectedResponse?._id === response._id ? 'bg-purple-50' : ''
                        }`}
                        onClick={() => setSelectedResponse(response)}
                      >
                        <div className="font-medium text-gray-900">{response.name}</div>
                        <div className="text-sm text-gray-500">{response.email}</div>
                        <div className="text-xs text-gray-400 mt-1">
                          {formatDate(response.createdAt)}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Response Details */}
          <div className="w-2/3">
            <div className="bg-white rounded-lg shadow-sm h-full">
              <div className="overflow-y-auto h-full" style={{ maxHeight: 'calc(100vh - 280px)' }}>
                {selectedResponse ? (
                  <div className="p-6">
                    <div className="flex justify-between items-start mb-6">
                      <div>
                        <h2 className="text-2xl font-bold text-gray-900 mb-1">
                          {selectedResponse.name}
                        </h2>
                        <p className="text-gray-600">{selectedResponse.email}</p>
                        <p className="text-sm text-gray-500 mt-1">
                          Submitted on {formatDate(selectedResponse.createdAt)}
                        </p>
                      </div>
                      <a
                        href={`mailto:${selectedResponse.email}`}
                        className="px-4 py-2 bg-purple-500 text-white rounded-lg hover:bg-purple-600 transition-colors flex items-center gap-2"
                      >
                        <FaEnvelope />
                        Contact User
                      </a>
                    </div>

                    {/* Resume File */}
                    <div className="mb-6 p-4 bg-gray-50 rounded-lg">
                      <h3 className="font-semibold text-gray-700 mb-2">Uploaded Resume</h3>
                      {selectedResponse.resumeFile ? (
                        <div className="flex items-center justify-between">
                          <span className="text-sm text-gray-600">
                            {selectedResponse.resumeFile.split('/').pop()}
                          </span>
                          <div className="flex gap-2">
                            <a
                              href={`${files}${selectedResponse.resumeFile}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors flex items-center gap-1"
                            >
                              <FaEye className="w-4 h-4" />
                              View
                            </a>
                            <a
                              href={`${files}${selectedResponse.resumeFile}`}
                              download
                              className="px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600 transition-colors flex items-center gap-1"
                            >
                              <FaDownload className="w-4 h-4" />
                              Download
                            </a>
                          </div>
                        </div>
                      ) : (
                        <p className="text-sm text-gray-500">No resume uploaded</p>
                      )}
                    </div>

                    {/* Job Links */}
                    <div className="mb-6">
                      <h3 className="font-semibold text-gray-700 mb-2">Job Opening Links</h3>
                      <div className="space-y-2">
                        <a href={selectedResponse.jobLink1} target="_blank" rel="noopener noreferrer" 
                           className="block text-blue-600 hover:underline">
                          {selectedResponse.jobLink1}
                        </a>
                        <a href={selectedResponse.jobLink2} target="_blank" rel="noopener noreferrer"
                           className="block text-blue-600 hover:underline">
                          {selectedResponse.jobLink2}
                        </a>
                      </div>
                    </div>

                    {/* Questionnaire Responses */}
                    <div className="space-y-6">
                      <div>
                        <h3 className="font-semibold text-gray-700 mb-2">
                          Biggest concerns with current resume:
                        </h3>
                        <p className="text-gray-600 bg-gray-50 p-3 rounded">
                          {selectedResponse.concerns || "No response provided"}
                        </p>
                      </div>

                      <div>
                        <h3 className="font-semibold text-gray-700 mb-2">
                          Strongest aspect of current resume:
                        </h3>
                        <p className="text-gray-600 bg-gray-50 p-3 rounded">
                          {selectedResponse.strengths || "No response provided"}
                        </p>
                      </div>

                      <div>
                        <h3 className="font-semibold text-gray-700 mb-2">
                          Obstacles hindering ability to secure a new position:
                        </h3>
                        <p className="text-gray-600 bg-gray-50 p-3 rounded">
                          {selectedResponse.obstacles || "No response provided"}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="h-full flex items-center justify-center text-gray-500 p-6">
                    Select a submission to view details
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Questionnaire;
