import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { handleSuccess, handleError } from '../../utils';
import axios from 'axios';


const clientkey = process.env.REACT_APP_CLIENTKEY;
const api = process.env.REACT_APP_LOCALHOST;
const decodeToken = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(jsonPayload);
};

const Payment = () => {
  const { token } = useParams();
  const navigate = useNavigate(); // Use useNavigate
  const [userInfo, setUserInfo] = useState(null);
  const [amount, setAmount] = useState("10.00"); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    try {
      const decoded = decodeToken(token);
      if (decoded) {
        setUserInfo(decoded);
        if (decoded.userpackage) setAmount(decoded.userpackage); 
      } else {
        throw new Error("Invalid token");
      }
    } catch (err) {
      setError("Failed to decode token. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [token]);

  if (loading) return <p className="text-gray-600 text-lg">Loading user information, please wait...</p>;
  if (error) return <p className="text-red-600 text-lg">{error}</p>;


  const handlePaymentSuccess = async () => {
    console.log('User Email:', userInfo.email); 
    console.log('Payment Status:', 'paid'); 

    try {
        const response = await fetch('http://localhost:8000/api/users/updatepayment', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                useremail: userInfo.email,
                status: 'paid',
            }),
        });

        if (!response.ok) {
            const errorResponse = await response.json();
            throw new Error(`Failed to update payment status: ${errorResponse.message || 'Unknown error'}`);
        }

        const data = await response.json();
        console.log(data); 
        navigate('/login'); 
    } catch (error) {
        console.error('Payment update error:', error);
        handleError(error); 
    }
};


  

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 p-6">
      <div className="bg-white shadow-md rounded-lg p-8 w-full max-w-md">
        {userInfo ? (
          <>
            <h3 className="text-2xl font-semibold mb-4 text-center text-gray-800">Welcome, {userInfo.name}</h3>
            <div className="mb-4">
              <p className="text-gray-700">Email: <strong>{userInfo.email}</strong></p>
              <p className="text-gray-700">Payment Status: <strong>{userInfo.paymentStatus}</strong></p>
              <p className="text-gray-700">Selected Package: <strong>{userInfo.packagename}</strong></p>
              <p className="text-gray-700">Amount Due: <strong className="text-lg text-green-600">${amount}</strong></p>
            </div>

            <div className="paypal-button-wrapper mt-6">
              <PayPalScriptProvider options={{ "client-id": clientkey }}>
                <PayPalButtons
                  createOrder={(data, actions) => {
                    return actions.order.create({
                      purchase_units: [{ amount: { value: amount } }]
                    });
                  }}
                  onApprove={(data, actions) => {
                    return actions.order.capture()
                      .then(handlePaymentSuccess) 
                      .catch(handleError);
                  }}
                  onError={handleError}
                />
              </PayPalScriptProvider>
            </div>
          </>
        ) : (
          <p className="text-gray-600">Loading user information, please wait...</p>
        )}
      </div>
    </div>
  );
};

export default Payment;
