import React from 'react';
import Icon from './Icon';

const SearchBar = () => {
  return (
    <div className="relative">
      <input
        type="text"
        placeholder="Search..."
        className="w-64 pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
      />
      <div className="absolute left-3 top-2.5">
        <Icon name="search" className="h-5 w-5 text-gray-400" />
      </div>
    </div>
  );
};

export default SearchBar; 