import React, { useState } from "react";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Footer = () => {
  const api = process.env.REACT_APP_LOCALHOST;
  const [data, setData] = useState({ name: '', email: '', phone: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((pre) => ({
      ...pre,
      [name]: value
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${api}news_letter`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data), // Pass the 'data' object
      });
  
      const responseData = await response.json();
      
      if (response.ok) {
        console.log('Data posted successfully:', responseData);
        toast.success('Thank you for subscribing!'); // Show success toast
      } else {
        console.error('Error posting data:', responseData);
        toast.error('Subscription failed. Please try again.'); // Show error toast
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An unexpected error occurred.'); // Show error toast on catch
    }
  
    console.log(data); // Log the data on form submission
  
    // Reset the form fields after submission
    setData({ name: '', email: '', phone: '' });
  };
  

  return (
    <>
    <footer className="bg-gray-900 text-white py-8">
      <div className="container mx-auto px-4">
        {/* Button */}
        <div className="text-center mb-6">
          <a href="/services">
            <button className="px-8 py-3 bg-violet-200 text-black font-semibold rounded-full shadow-md hover:bg-violet-300 transition duration-300">
              Get a Better Resume Today
            </button>
          </a>
        </div>

        {/* Separator Line */}
        <div className="text-center mb-6">
          <hr className="border-violet-200 border-2" />
        </div>

        {/* Footer Content */}
        <div className="flex flex-col md:flex-row justify-between items-start">
          {/* Left Column */}
          <div className="flex flex-col md:flex-row md:w-1/2 space-y-6 md:space-y-0 md:space-x-12 p-6 md:p-12 border-b md:border-b-0 md:border-r-2 border-violet-200">
            {/* Services */}
            <div className="flex-1">
              <h4 className="text-lg text-violet-200 font-semibold mb-4">Services</h4>
              <ul>
                <li className="mb-2"><a href="/services" className="hover:underline">Resume Writing</a></li>
                <li className="mb-2"><a href="/services" className="hover:underline">Cover Letter Writing</a></li>
                <li className="mb-2"><a href="/services" className="hover:underline">LinkedIn Makeover</a></li>
                <li className="mb-2"><a href="/" className="hover:underline">Resume Review</a></li>
              </ul>
            </div>

            {/* Company */}
            <div className="flex-1">
              <h4 className="text-lg text-violet-200 font-semibold mb-4">Company</h4>
              <ul>
                <li className="mb-2"><a href="/services" className="hover:underline">Pricing</a></li>
                <li className="mb-2"><a href="/writers" className="hover:underline">Our Team</a></li>
                <li className="mb-2"><a href="/testimonials" className="hover:underline">Testimonials</a></li>
                <li className="mb-2"><a href="/faqs" className="hover:underline">FAQs</a></li>
                <li className="mb-2"><a href="/about" className="hover:underline">About</a></li>
                <li className="mb-2"><a href="/contact" className="hover:underline">Contact Us</a></li>
              </ul>
            </div>

            {/* Legal Information */}
            <div className="flex-1">
              <h4 className="text-lg text-violet-200 font-semibold mb-4">Legal Information</h4>
              <ul>
                <li className="mb-2"><a href="/terms" className="hover:underline">Terms and Conditions</a></li>
                <li className="mb-2"><a href="/privacy" className="hover:underline">Privacy Policy</a></li>
                <li className="mb-2"><a href="/cookie" className="hover:underline">Cookie Policy</a></li>
                <li className="mb-2"><a href="/data Policy" className="hover:underline">Do Not Sell</a></li>
              </ul>
            </div>
          </div>

          {/* Right Column */}
          <div className="md:w-[40%] flex flex-col p-6 md:p-12">
            <h4 className="text-lg text-violet-200 font-semibold mb-4">Newsletter</h4>
            <p className="mb-4">Sign up to our email newsletter for limited edition offers, new announcements, and more special surprises!</p>

            {/* Form Submission */}
            <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
              <input
                name="name"
                value={data.name}
                onChange={handleChange}
                type="text"
                placeholder="Enter your name"
                className="w-full px-4 py-2 rounded-lg border border-gray-700 bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
              <input
                name="email"
                value={data.email}
                onChange={handleChange}
                type="email"
                placeholder="Enter your email"
                className="w-full px-4 py-2 rounded-lg border border-gray-700 bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
              <input
                name="phone"
                value={data.phone}
                onChange={handleChange}
                type="tel"
                placeholder="Enter your phone number"
                className="w-full px-4 py-2 rounded-lg border border-gray-700 bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
              <button
                type="submit"
                className="w-full px-4 py-2 bg-violet-200 text-black font-semibold rounded-lg hover:bg-violet-300 transition duration-300"
              >
                Subscribe
              </button>
            </form>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="mt-8 flex flex-col md:flex-row justify-between items-center border-t-2 border-violet-200 px-8 pt-6">
          {/* Left Section */}
          <div className="text-sm text-gray-400">
            <b>Peerless Resume |</b> 2024 PeerlessResume, All Rights Reserved.
          </div>

          {/* Right Section */}
          <div className="flex space-x-6 mt-4 md:mt-0">
            <span className="text-gray-400">Follow Us on Social Media:</span>
            <a href="#" className="text-indigo-500 hover:text-indigo-600 transition duration-300">
              <FaFacebookF />
            </a>
            <a href="#" className="text-indigo-500 hover:text-indigo-600 transition duration-300">
              <FaTwitter />
            </a>
            <a href="#" className="text-indigo-500 hover:text-indigo-600 transition duration-300">
              <FaLinkedinIn />
            </a>
          </div>
        </div>
      </div>
    </footer>
    <ToastContainer />
    </>
  );
};

export default Footer;
