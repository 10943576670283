import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Forget() {
    const api = process.env.REACT_APP_LOCALHOST;

  const [email, setEmail] = useState('');

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSuccess = (message) => {
    toast.success(message); 
    setEmail('');  
  };

  const handleError = (message) => {
    toast.error(message);   };

    const handleSubmit = async (e) => {
        e.preventDefault();
      
        if (!email) {
          handleError("Email field cannot be empty");
          return;
        }
      
        const url =`${api}users/forgetpassword`;
        console.log(url)
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        })
          try{
            if(!response.ok){
                const data = await response.json();

                handleError(data.message)
            }

            else{
                const data = await response.json();
                handleSuccess(data.message);
            }
          }
          catch (error) {
            console.error('Error:', error);
            handleError('Failed to send email. Please try again later.');
          }
      };
      

  return (
    <>
      
      <div className="bg-white py-6 sm:py-8 lg:py-12">
        <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
          <div className="flex flex-col items-center rounded-lg bg-gray-100 p-4 sm:p-8">
            <form onSubmit={handleSubmit} className="mb-3 flex w-full max-w-md gap-2 sm:mb-5">
              <input
                onChange={handleChange}
                value={email}
                placeholder="Email"
                className="bg-gray-white w-full flex-1 rounded border border-gray-300 px-3 py-2 text-gray-800 placeholder-gray-400 outline-none ring-indigo-300 transition duration-100 focus:ring"
              />
              <button type="submit" className="inline-block rounded bg-indigo-500 px-8 py-2 text-center text-sm font-semibold text-white outline-none ring-indigo-300 transition duration-100 hover:bg-indigo-600 focus-visible:ring active:bg-indigo-700 md:text-base">
                Send
              </button>
            </form>
            <p className="text-center text-xs text-gray-400">
              By signing up to our newsletter you agree to our{' '}
              <a href="#" className="underline transition duration-100 hover:text-indigo-500 active:text-indigo-600">
                Term of Service
              </a>{' '}
              and{' '}
              <a href="#" className="underline transition duration-100 hover:text-indigo-500 active:text-indigo-600">
                Privacy Policy
              </a>.
            </p>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop closeOnClick pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
}
