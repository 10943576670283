import React, { useState, useEffect, useRef } from 'react';
import { IoSend, IoEllipsisVertical, IoArrowBack } from 'react-icons/io5';
import { FaSmile, FaPaperclip } from 'react-icons/fa';
import UserLayout from '../components/UserLayout';

const Chat = () => {
  const [activeChat, setActiveChat] = useState(null);
  const [message, setMessage] = useState('');
  const messagesEndRef = useRef(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

  // Mock data for support chat
  const supportChat = {
    id: 1,
    name: 'Resume Support',
    avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
    role: 'Customer Support',
    online: true,
    messages: [
      {
        id: 1,
        content: "Welcome to Peerless Resume Support! How can we assist you today?",
        sender: 'support',
        timestamp: '2024-03-15T10:00:00Z'
      },
      {
        id: 2,
        content: "Hi! I have some questions about the resume writing process.",
        sender: 'user',
        timestamp: '2024-03-15T10:05:00Z'
      },
      {
        id: 3,
        content: "Of course! I'd be happy to help. What would you like to know about our resume writing services?",
        sender: 'support',
        timestamp: '2024-03-15T10:07:00Z'
      },
      {
        id: 4,
        content: "What information do I need to provide for my resume?",
        sender: 'user',
        timestamp: '2024-03-15T10:08:00Z'
      },
      {
        id: 5,
        content: "For a comprehensive resume, we recommend providing: \n• Your work history\n• Educational background\n• Skills and certifications\n• Professional achievements\n• Contact information\nDon't worry if you're not sure about something - we'll guide you through the process!",
        sender: 'support',
        timestamp: '2024-03-15T10:10:00Z'
      }
    ]
  };

  useEffect(() => {
    setActiveChat(supportChat);
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [activeChat?.messages]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const sendMessage = (e) => {
    e.preventDefault();
    if (!message.trim() || !activeChat) return;

    const newMessage = {
      id: activeChat.messages.length + 1,
      content: message,
      sender: 'user',
      timestamp: new Date().toISOString(),
    };

    setActiveChat(prev => ({
      ...prev,
      messages: [...prev.messages, newMessage],
    }));

    setMessage('');
  };

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  return (
    <UserLayout>
      <div className="flex h-[calc(100vh-4rem)] bg-gray-100">
        {/* Chat Area */}
        <div className="flex-1 flex flex-col h-full">
          {activeChat ? (
            <>
              {/* Chat Header */}
              <div className="h-16 flex items-center justify-between px-4 bg-white border-b">
                <div className="flex items-center">
                  <img
                    src={activeChat.avatar}
                    alt={activeChat.name}
                    className="w-10 h-10 rounded-full"
                  />
                  <div className="ml-4">
                    <h3 className="font-semibold text-gray-800">{activeChat.name}</h3>
                    <p className="text-xs text-gray-500">
                      {activeChat.online ? "Online" : "Offline"}
                    </p>
                  </div>
                </div>
                <button className="p-2 hover:bg-gray-100 rounded-full">
                  <IoEllipsisVertical className="w-5 h-5 text-gray-500" />
                </button>
              </div>

              {/* Messages Area */}
              <div className="flex-1 bg-violet-50 overflow-y-auto">
                <div className="max-w-3xl mx-auto px-4 py-6 space-y-4">
                  {activeChat.messages.map((msg) => (
                    <div
                      key={msg.id}
                      className={`flex ${msg.sender === 'user' ? 'justify-end' : 'justify-start'}`}
                    >
                      <div
                        className={`max-w-[85%] md:max-w-[70%] rounded-lg p-3 ${
                          msg.sender === 'user'
                            ? 'bg-purple-500 text-white'
                            : 'bg-white'
                        }`}
                      >
                        <p className="break-words whitespace-pre-line">{msg.content}</p>
                        <p className={`text-xs ${msg.sender === 'user' ? 'text-purple-200' : 'text-gray-500'} text-right mt-1`}>
                          {formatTime(msg.timestamp)}
                        </p>
                      </div>
                    </div>
                  ))}
                  <div ref={messagesEndRef} />
                </div>
              </div>

              {/* Message Input */}
              <div className="h-16 bg-white border-t px-4">
                <form onSubmit={sendMessage} className="h-full flex items-center gap-2 max-w-3xl mx-auto">
                  <button
                    type="button"
                    className="h-10 w-10 flex items-center justify-center hover:bg-gray-100 rounded-full text-gray-500 hidden md:flex"
                  >
                    <FaSmile className="w-5 h-5" />
                  </button>
                  <button
                    type="button"
                    className="h-10 w-10 flex items-center justify-center hover:bg-gray-100 rounded-full text-gray-500 hidden md:flex"
                  >
                    <FaPaperclip className="w-5 h-5" />
                  </button>
                  <input
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Type your message here..."
                    className="flex-1 h-10 px-4 bg-gray-100 rounded-lg focus:outline-none"
                  />
                  <button
                    type="submit"
                    disabled={!message.trim()}
                    className="h-10 w-10 flex items-center justify-center bg-purple-500 hover:bg-purple-600 rounded-full text-white disabled:opacity-50"
                  >
                    <IoSend className="w-5 h-5" />
                  </button>
                </form>
              </div>
            </>
          ) : (
            <div className="flex-1 flex items-center justify-center bg-violet-50">
              <div className="text-center">
                <h3 className="text-xl font-semibold text-gray-700 mb-2">Welcome to Resume Support</h3>
                <p className="text-gray-500">Ask us anything about our resume services!</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </UserLayout>
  );
};

export default Chat;
