import React, { useState } from "react";
import HowItWorks from "../../components/homecomponents/HowItWorks";
import Logos from "../../components/homecomponents/Logos";
import ReviewSection from "../../components/homecomponents/ReviewSection";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import { handleError, handleSuccess } from "../../utils";

const Home = () => {
  const api = process.env.REACT_APP_LOCALHOST;
  const [fileName, setFileName] = useState("");

 

 
  const [file, setFile] = useState(null);
  const [email, setEmail] = useState("");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
        setFileName(file.name); 
    }
    setFile(e.target.files[0]); 
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value); 
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      alert("Please upload a file.");
      return;
    }
    
    if (!email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      alert("Please enter a valid email address.");
      return;
    }

    const formData = new FormData();
    formData.append("resume", file);
    formData.append("email", email);

    try {
      const response = await fetch(`${api}resumes/upload`, {
        method: "POST",
        body: formData,
      });
      const result = await response.json();
      if (response.ok) {
        handleSuccess("Resume uploaded successfully!");
        setFileName("");
        setEmail("");
      } else {
        handleError(`Error: ${result.error}`);
      }
    } catch (error) {
      handleError("Error uploading file:", error);
    }
  };

  return (
    <>
      <Navbar />
      <section className="flex-col md:flex md:flex-row items-start md:items-center justify-between p-8 bg-violet-50 pt-28">
            {/* Left Side */}
            <div className="w-full md:w-1/2 px-6 md:px-12 lg:px-20 mb-8 md:mb-0">
                <h1 className="text-4xl md:text-4xl font-bold text-indigo-400 mb-4">
                    Peerless Resume Writes, Careers Take Flight
                </h1>
                <p className="text-lg md:text-xl text-gray-700 mb-6">
                    Unlocking Your Career Potential with Precision Resume Writing
                </p>
                <a href="/services">
                    <button className="px-8 py-3 bg-violet-200 font-semibold rounded-full shadow-md hover:bg-violet-300 transition duration-300 ease-in-out">
                        Get Started
                    </button>
                </a>
            </div>

         
            <div className="w-full lg:w-1/3 px-6 lg:mr-20 md:px-10 lg:px-14 py-6 bg-white border border-gray-300 rounded-lg shadow-md flex flex-col items-center">
                <form onSubmit={handleSubmit} className="w-full flex flex-col items-center">
                    <div className="w-full relative border-4 border-dotted border-gray-400 text-gray-700 px-8 py-12 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-opacity-75 mb-6">
                        <input
                            type="file"
                            accept=".pdf,.doc,.docx"
                            className="w-full h-full opacity-0 absolute top-0 left-0 cursor-pointer"
                            onChange={handleFileChange}
                            required
                        />
                        <div className="flex justify-center items-center mb-4">
                            <img src="./images/Critic.svg" alt="Critic" className="w-10 md:w-12" />
                        </div>
                        <p className="text-center">Drag and Drop Here</p>
                        {fileName && <p className="text-center mt-2 text-gray-600">Selected file: {fileName}</p>}
                    </div>

                    <input
                        type="email"
                        placeholder="Enter your email"
                        className="w-full border border-gray-300 px-4 py-2 rounded-md mb-6"
                        value={email}
                        onChange={handleEmailChange}
                        required
                    />

                    <button
                        type="submit"
                        className="w-full bg-violet-200 px-8 py-3 rounded-full hover:bg-violet-300 focus:outline-none focus:ring-2 focus:ring-violet-400 focus:ring-opacity-75"
                    >
                        Request Evaluation
                    </button>
                </form>
            </div>
        </section>

      <HowItWorks />
      <Logos />
      <section className="flex flex-col md:flex-row items-center justify-between bg-gray-100 p-16">
        <div className="md:w-1/2 flex justify-center items-center mb-6 md:mb-0">
          <img
            src="./images/home 1.png"
            alt="Service Illustration"
            className="rounded-lg shadow-lg object-cover"
          />
        </div>
        <div className="md:w-1/2 px-6 md:px-12 text-center md:text-left">
          <h1 className="text-3xl md:text-4xl font-semibold text-indigo-500 mb-6">
            Peerless Resume Turns Dreams into Careers
          </h1>
          <h2 className="text-xl md:text-2xl font-semibold text-gray-700 mb-6">
            Choose Our Resume Writing Services and Get:
          </h2>
          <ul className="list-disc list-inside text-base md:text-lg leading-relaxed text-gray-800">
            <li className="mb-2">Personalized assistance from an expert writer.</li>
            <li className="mb-2">Customized resume showcasing your career journey.</li>
            <li className="mb-2">Revisions until you are satisfied with the result.</li>
            <li>Optimized keywords for effective ATS screening.</li>
          </ul>
        </div>
      </section>

      <ReviewSection />

      <Footer />
    </>
  );
};

export default Home;
