import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { 
  ArrowLeftIcon,
  ClockIcon, 
  CheckCircleIcon,
  DocumentTextIcon,
  ChatBubbleLeftRightIcon
} from '@heroicons/react/24/outline';
import UserLayout from '../components/UserLayout';

const OrderDetails = () => {
  const { orderId } = useParams();

  // Mock order data based on your services
  const orderDetails = {
    id: orderId,
    orderNumber: 'ORD-2024-001',
    service: 'Professional Resume Package',
    status: 'in-progress',
    date: '2024-03-15',
    package: {
      label: 'Professional',
      price: '$99',
      features: [
        'Professional Resume Writing',
        'Cover Letter',
        '2 Revisions',
        '48-hour Delivery'
      ]
    },
    timeline: [
      { id: 1, status: 'Order Placed', date: '2024-03-15 09:00 AM', completed: true },
      { id: 2, status: 'Document Upload', date: '2024-03-15 10:30 AM', completed: true },
      { id: 3, status: 'Writing in Progress', date: '2024-03-15 11:00 AM', completed: true },
      { id: 4, status: 'Review & Formatting', date: 'Pending', completed: false },
      { id: 5, status: 'Final Delivery', date: 'Pending', completed: false },
    ],
    documents: [
      { 
        id: 1, 
        name: 'Current_Resume.pdf', 
        type: 'Original Document', 
        date: '2024-03-15',
        status: 'Uploaded'
      },
      { 
        id: 2, 
        name: 'Job_Description.pdf', 
        type: 'Additional Document', 
        date: '2024-03-15',
        status: 'Uploaded'
      },
      { 
        id: 3, 
        name: 'Final_Resume.pdf', 
        type: 'Deliverable', 
        date: 'Pending',
        status: 'In Progress'
      }
    ]
  };

  const getStatusBadge = (status) => {
    switch (status) {
      case 'completed':
        return (
          <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800">
            <CheckCircleIcon className="mr-1 h-5 w-5" />
            Completed
          </span>
        );
      case 'in-progress':
        return (
          <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800">
            <ClockIcon className="mr-1 h-5 w-5" />
            In Progress
          </span>
        );
      default:
        return (
          <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-gray-100 text-gray-800">
            Pending
          </span>
        );
    }
  };

  return (
    <UserLayout>
      <div className="max-w-7xl mx-auto">
        {/* Back Button */}
        <div className="mb-6">
          <Link
            to="/myorder"
            className="inline-flex items-center text-gray-600 hover:text-gray-900"
          >
            <ArrowLeftIcon className="h-5 w-5 mr-2" />
            Back to Orders
          </Link>
        </div>

        {/* Order Header */}
        <div className="bg-white rounded-lg shadow mb-6">
          <div className="px-6 py-4 border-b border-gray-200">
            <div className="flex justify-between items-center">
              <div>
                <h2 className="text-xl font-semibold text-gray-900">
                  Order #{orderDetails.orderNumber}
                </h2>
                <p className="text-sm text-gray-500 mt-1">
                  Placed on {orderDetails.date}
                </p>
              </div>
              <div>{getStatusBadge(orderDetails.status)}</div>
            </div>
          </div>
          
          <div className="px-6 py-4">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div>
                <h3 className="text-sm font-medium text-gray-500">Service</h3>
                <p className="mt-1 text-sm text-gray-900">{orderDetails.service}</p>
              </div>
              <div>
                <h3 className="text-sm font-medium text-gray-500">Amount</h3>
                <p className="mt-1 text-sm text-gray-900">${orderDetails.package.price}</p>
              </div>
              <div>
                <Link
                  to="/user/chat"
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700"
                >
                  <ChatBubbleLeftRightIcon className="h-5 w-5 mr-2" />
                  Contact Reviewer
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Package Details */}
        <div className="bg-white rounded-lg shadow mb-6">
          <div className="px-6 py-4 border-b border-gray-200">
            <h3 className="text-lg font-medium text-gray-900">Package Details</h3>
          </div>
          <div className="px-6 py-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <h4 className="text-lg font-medium text-gray-900">{orderDetails.package.label} Package</h4>
                <p className="text-2xl font-bold text-gray-900 mt-2">{orderDetails.package.price}</p>
                <ul className="mt-4 space-y-2">
                  {orderDetails.package.features.map((feature, index) => (
                    <li key={index} className="flex items-center text-gray-600">
                      <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg">
                <h4 className="text-lg font-medium text-gray-900 mb-4">Important Notes</h4>
                <ul className="space-y-2 text-sm text-gray-600">
                  <li>• Delivery timeline starts after document submission</li>
                  <li>• Revisions must be requested within 7 days</li>
                  <li>• All documents are delivered in PDF format</li>
                  <li>• Additional revisions available at extra cost</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Order Timeline */}
        <div className="bg-white rounded-lg shadow mb-6">
          <div className="px-6 py-4 border-b border-gray-200">
            <h3 className="text-lg font-medium text-gray-900">Order Timeline</h3>
          </div>
          <div className="px-6 py-4">
            <div className="flow-root">
              <ul className="-mb-8">
                {orderDetails.timeline.map((event, eventIdx) => (
                  <li key={event.id}>
                    <div className="relative pb-8">
                      {eventIdx !== orderDetails.timeline.length - 1 ? (
                        <span
                          className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                          aria-hidden="true"
                        />
                      ) : null}
                      <div className="relative flex space-x-3">
                        <div>
                          <span
                            className={`h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white ${
                              event.completed ? 'bg-green-500' : 'bg-gray-300'
                            }`}
                          >
                            <CheckCircleIcon
                              className="h-5 w-5 text-white"
                              aria-hidden="true"
                            />
                          </span>
                        </div>
                        <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                          <div>
                            <p className="text-sm text-gray-500">
                              {event.status}
                            </p>
                          </div>
                          <div className="text-right text-sm whitespace-nowrap text-gray-500">
                            {event.date}
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {/* Documents */}
        <div className="bg-white rounded-lg shadow">
          <div className="px-6 py-4 border-b border-gray-200">
            <h3 className="text-lg font-medium text-gray-900">Documents</h3>
          </div>
          <div className="px-6 py-4">
            <ul className="divide-y divide-gray-200">
              {orderDetails.documents.map((doc) => (
                <li key={doc.id} className="py-4">
                  <div className="flex items-center space-x-4">
                    <div className="flex-shrink-0">
                      <DocumentTextIcon className="h-8 w-8 text-gray-400" />
                    </div>
                    <div className="flex-1 min-w-0">
                      <p className="text-sm font-medium text-gray-900 truncate">
                        {doc.name}
                      </p>
                      <p className="text-sm text-gray-500">
                        {doc.type} • Uploaded on {doc.date}
                      </p>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                      >
                        Download
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </UserLayout>
  );
};

export default OrderDetails; 