import React from 'react';

const NotificationsDropdown = ({ notifications, onClose }) => {
  return (
    <div className="absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-lg py-1 z-50">
      <div className="px-4 py-2 border-b">
        <h3 className="text-sm font-semibold text-gray-700">Notifications</h3>
      </div>
      <div className="max-h-96 overflow-y-auto">
        {notifications.length > 0 ? (
          notifications.map((notification, index) => (
            <div
              key={index}
              className="px-4 py-3 hover:bg-gray-50 border-b last:border-b-0"
            >
              <p className="text-sm text-gray-800">{notification.message}</p>
              <p className="text-xs text-gray-500 mt-1">
                {new Date(notification.timestamp).toLocaleString()}
              </p>
            </div>
          ))
        ) : (
          <div className="px-4 py-3 text-sm text-gray-500">
            No new notifications
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationsDropdown; 