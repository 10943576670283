// src/adminpanel/Header.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SearchBar from './SearchBar';
import NotificationIcon from './NotificationIcon';
import NotificationsDropdown from './NotificationsDropdown';
import UserMenuDropdown from './UserMenuDropdown';

const Header = () => {
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);

  useEffect(() => {
    // Fetch notifications
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_LOCALHOST}notifications`);
        setNotifications(response.data);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };
    fetchNotifications();
  }, []);

  const handleLogout = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_LOCALHOST}auth/logout`);
      // Handle logout (redirect, clear state, etc.)
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <header className="bg-gray-100 shadow-sm z-20">
      <div className="flex items-center justify-between px-4 md:px-6 py-3 md:py-4">
        {/* Left side - empty space for mobile menu button */}
        <div className="w-8 md:w-auto"></div>
        
        <div className="flex items-center space-x-2 md:space-x-4">
          {/* Search - Hidden on mobile */}
          <div className="hidden md:flex items-center">
            <SearchBar />
          </div>

          {/* Notifications */}
          <div className="relative">
            <button 
              onClick={() => setShowNotifications(!showNotifications)}
              className="p-1.5 md:p-2 hover:bg-gray-100 rounded-full relative"
            >
              {notifications.length > 0 && (
                <span className="absolute top-0 right-0 h-4 w-4 bg-red-500 rounded-full text-xs text-white flex items-center justify-center">
                  {notifications.length}
                </span>
              )}
              <NotificationIcon />
            </button>
            
            {showNotifications && (
              <div className="absolute right-0 mt-2 w-80 md:w-96">
                <NotificationsDropdown 
                  notifications={notifications}
                  onClose={() => setShowNotifications(false)}
                />
              </div>
            )}
          </div>

          {/* User Menu */}
          <div className="relative">
            <button 
              onClick={() => setShowUserMenu(!showUserMenu)}
              className="flex items-center space-x-2 md:space-x-3 hover:bg-gray-100 rounded-lg p-1.5 md:p-2"
            >
              <img
                src="https://ui-avatars.com/api/?name=Admin+User"
                alt="Admin"
                className="h-7 w-7 md:h-8 md:w-8 rounded-full"
              />
              <span className="hidden md:block text-sm font-medium text-gray-700">
                Admin User
              </span>
            </button>

            {showUserMenu && (
              <div className="absolute right-0 mt-2 w-48">
                <UserMenuDropdown 
                  onLogout={handleLogout}
                  onClose={() => setShowUserMenu(false)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
