import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export default function Refhandler({ setauthenticated }) {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const tokenPath = '/verify/';
        
        // Check if user is authenticated
        if (localStorage.getItem('jwtToken')) {
            setauthenticated(true);

            // Block access to specific paths
            if (
                // location.pathname === '/' ||
                location.pathname === '/login' ||
                location.pathname === '/signup' ||
                location.pathname === '/services' ||
                location.pathname.startsWith(tokenPath) // Block any path that starts with /verify/
            ) {
                navigate('/', { replace: true });  // Redirect to home
            }
        }
    }, [location, navigate, setauthenticated]);

    return <div></div>;
}
