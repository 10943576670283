import React from 'react';
import Icon from './Icon';

const DashboardCard = ({ title, value, icon, color }) => {
  const colorClasses = {
    blue: 'bg-blue-50 text-blue-600',
    green: 'bg-green-50 text-green-600',
    yellow: 'bg-yellow-50 text-yellow-600',
    purple: 'bg-purple-50 text-purple-600',
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-6 hover:shadow-md transition-shadow">
      <div className="flex items-center">
        <div className={`p-3 rounded-full ${colorClasses[color]}`}>
          <Icon name={icon} className="w-6 h-6" />
        </div>
        <div className="ml-4">
          <p className="text-gray-500 text-sm">{title}</p>
          <h3 className="text-2xl font-semibold text-gray-700">{value}</h3>
        </div>
      </div>
    </div>
  );
};

export default DashboardCard; 