import React from 'react';
import { Link } from 'react-router-dom';

const UserMenuDropdown = ({ onLogout, onClose }) => {
  return (
    <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 z-50">
      <Link
        to="/admin/profile"
        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
        onClick={onClose}
      >
        Your Profile
      </Link>
      <Link
        to="/admin/settings"
        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
        onClick={onClose}
      >
        Settings
      </Link>
      <button
        onClick={onLogout}
        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
      >
        Sign out
      </button>
    </div>
  );
};

export default UserMenuDropdown; 