import React, { useState } from 'react'
import { handleError ,handleSuccess} from '../../utils';
import { ToastContainer } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../../components/navbar/Navbar';


export default function Login() {
    const api = process.env.REACT_APP_LOCALHOST;


  const navigate = useNavigate();

  const [data , setdata] = useState({
    
    email: '',
    password: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setdata({
      ...data,   
      [name]: value 
    });
  };

  console.log('logininfo ->', data);
  const handelLogin = async (e) => {
    e.preventDefault();
    
    const {email, password } = data;
  
    if (!email || !password) {
      return handleError('email, and password must be specified');
    }
  
    try {
      const url = `${api}users/login`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),

      });



      if (!response.ok) {
        const errorData = await response.json();
        console.log(errorData);
        return handleError(errorData.error  || errorData.message);
      }
  
      const result = await response.json();
    const   {success , message, name, jwtToken}  = result
      if(success){
        localStorage.setItem('jwtToken', jwtToken);
        localStorage.setItem('logedinUser', name);
        handleSuccess(message);
        setTimeout(() => {
          navigate('/')
          
        }, 1000);
      }
      console.log('Login successful:', result);
      
    } catch (err) {
      console.log('Error:', err);
      handleError(err);
    }
  };
  


 
  return (
    <>
    <Navbar />
      <div className="bg-white py-6 sm:py-8 lg:py-12 mt-20">
        <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
          <h2 className="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-8 lg:text-3xl">Login</h2>

          <form onSubmit={handelLogin} className="mx-auto max-w-lg rounded-lg border">
            <div className="flex flex-col gap-4 p-4 md:p-8">
              

              <div>
                <label htmlFor="email" className="mb-2 inline-block text-sm text-gray-800 sm:text-base">Email</label>
                <input name="email" value={data.email} onChange={handleChange} className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring" />
              </div>

              <div>
                <label htmlFor="password" className="mb-2 inline-block text-sm text-gray-800 sm:text-base">Password</label>
                <input name="password" value={data.password} onChange={handleChange} className="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring" />
              </div>

              <button type="submit" className="block rounded-lg bg-gray-800 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-gray-300 transition duration-100 hover:bg-gray-700 focus-visible:ring active:bg-gray-600 md:text-base">Login</button>
            </div>

            <div className="flex items-center justify-center bg-gray-100 p-4">
              <p className="text-center text-sm text-gray-500">Don't have an account? <Link  to='/signup' className="text-indigo-500 transition duration-100 hover:text-indigo-600 active:text-indigo-700">Sign Up</Link></p>
            </div>
            <div className="flex items-center justify-center bg-gray-100 p-4">
              <p className="text-center text-sm text-gray-500"><Link  to='/forget-password' className="text-indigo-500 transition duration-100 hover:text-indigo-600 active:text-indigo-700">Forget Password</Link></p>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer/>
    </>
  );
}
