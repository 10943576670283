import React from 'react';
import { 
  AiOutlineHome,
  AiOutlineMessage,
  AiOutlineMail,
  AiOutlineFileText,
  AiOutlineShoppingCart,
  AiOutlineFileSearch,
  AiOutlineForm,
  AiOutlineUser,
  AiOutlineSearch,
  AiOutlineBell
} from 'react-icons/ai';

const iconComponents = {
  dashboard: AiOutlineHome,
  chat: AiOutlineMessage,
  mail: AiOutlineMail,
  newspaper: AiOutlineFileText,
  'shopping-cart': AiOutlineShoppingCart,
  'document-check': AiOutlineFileSearch,
  'clipboard-list': AiOutlineForm,
  users: AiOutlineUser,
  search: AiOutlineSearch,
  bell: AiOutlineBell,
};

const Icon = ({ name, className }) => {
  const IconComponent = iconComponents[name];
  
  if (!IconComponent) {
    console.warn(`Icon "${name}" not found`);
    return null;
  }

  return <IconComponent className={className} />;
};

export default Icon; 