import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import { FaSearch, FaSpinner, FaEye, FaDownload } from 'react-icons/fa';
import AdminLayout from '../components/AdminLayout';

const Orders = () => {
  // Fake orders data
  const dummyOrders = [
    {
      id: "1",
      orderNumber: "ORD-2024-001",
      customerName: "John Smith",
      customerEmail: "john@example.com",
      package: "Premium Resume Package",
      amount: 299.99,
      date: "2024-03-15",
      status: "completed"
    },
    {
      id: "2",
      orderNumber: "ORD-2024-002",
      customerName: "Sarah Johnson",
      customerEmail: "sarah@example.com",
      package: "Basic Resume Package",
      amount: 149.99,
      date: "2024-03-14",
      status: "pending"
    },
    {
      id: "3",
      orderNumber: "ORD-2024-003",
      customerName: "Michael Brown",
      customerEmail: "michael@example.com",
      package: "Professional Package",
      amount: 399.99,
      date: "2024-03-13",
      status: "completed"
    },
    {
      id: "4",
      orderNumber: "ORD-2024-004",
      customerName: "Emma Wilson",
      customerEmail: "emma@example.com",
      package: "Cover Letter Package",
      amount: 99.99,
      date: "2024-03-12",
      status: "cancelled"
    },
    {
      id: "5",
      orderNumber: "ORD-2024-005",
      customerName: "David Lee",
      customerEmail: "david@example.com",
      package: "Premium Resume Package",
      amount: 299.99,
      date: "2024-03-11",
      status: "pending"
    },
    {
      id: "6",
      orderNumber: "ORD-2024-006",
      customerName: "Lisa Anderson",
      customerEmail: "lisa@example.com",
      package: "Professional Package",
      amount: 399.99,
      date: "2024-03-10",
      status: "completed"
    },{
      id: "7",
      orderNumber: "ORD-2024-001",
      customerName: "John Smith",
      customerEmail: "john@example.com",
      package: "Premium Resume Package",
      amount: 299.99,
      date: "2024-03-15",
      status: "completed"
    },
    {
      id: "8",
      orderNumber: "ORD-2024-002",
      customerName: "Sarah Johnson",
      customerEmail: "sarah@example.com",
      package: "Basic Resume Package",
      amount: 149.99,
      date: "2024-03-14",
      status: "pending"
    },
    {
      id: "9",
      orderNumber: "ORD-2024-003",
      customerName: "Michael Brown",
      customerEmail: "michael@example.com",
      package: "Professional Package",
      amount: 399.99,
      date: "2024-03-13",
      status: "completed"
    },
    {
      id: "10",
      orderNumber: "ORD-2024-004",
      customerName: "Emma Wilson",
      customerEmail: "emma@example.com",
      package: "Cover Letter Package",
      amount: 99.99,
      date: "2024-03-12",
      status: "cancelled"
    },
    {
      id: "11",
      orderNumber: "ORD-2024-005",
      customerName: "David Lee",
      customerEmail: "david@example.com",
      package: "Premium Resume Package",
      amount: 299.99,
      date: "2024-03-11",
      status: "pending"
    },
    {
      id: "12",
      orderNumber: "ORD-2024-006",
      customerName: "Lisa Anderson",
      customerEmail: "lisa@example.com",
      package: "Professional Package",
      amount: 399.99,
      date: "2024-03-10",
      status: "completed"
    },{
      id: "13",
      orderNumber: "ORD-2024-001",
      customerName: "John Smith",
      customerEmail: "john@example.com",
      package: "Premium Resume Package",
      amount: 299.99,
      date: "2024-03-15",
      status: "completed"
    },
    {
      id: "14",
      orderNumber: "ORD-2024-002",
      customerName: "Sarah Johnson",
      customerEmail: "sarah@example.com",
      package: "Basic Resume Package",
      amount: 149.99,
      date: "2024-03-14",
      status: "pending"
    },
    {
      id: "15",
      orderNumber: "ORD-2024-003",
      customerName: "Michael Brown",
      customerEmail: "michael@example.com",
      package: "Professional Package",
      amount: 399.99,
      date: "2024-03-13",
      status: "completed"
    },
    {
      id: "16",
      orderNumber: "ORD-2024-004",
      customerName: "Emma Wilson",
      customerEmail: "emma@example.com",
      package: "Cover Letter Package",
      amount: 99.99,
      date: "2024-03-12",
      status: "cancelled"
    },
    {
      id: "17",
      orderNumber: "ORD-2024-005",
      customerName: "David Lee",
      customerEmail: "david@example.com",
      package: "Premium Resume Package",
      amount: 299.99,
      date: "2024-03-11",
      status: "pending"
    },
    {
      id: "18",
      orderNumber: "ORD-2024-006",
      customerName: "Lisa Anderson",
      customerEmail: "lisa@example.com",
      package: "Professional Package",
      amount: 399.99,
      date: "2024-03-10",
      status: "completed"
    }
  ];

  // States
  const [orders, setOrders] = useState(dummyOrders);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState('all');
  const tableContainerRef = useRef(null);
  const cardContainerRef = useRef(null);

  const ITEMS_PER_PAGE = {
    MOBILE: 5,
    DESKTOP: 10
  };

  // Add new states for mobile pagination
  const [mobilePage, setMobilePage] = useState(1);
  const [mobileLoading, setMobileLoading] = useState(false);
  const [mobileHasMore, setMobileHasMore] = useState(true);

  // Filter orders
  const filteredOrders = useMemo(() => {
    return orders.filter(order => {
      const matchesSearch = 
        order.orderNumber?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        order.customerName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        order.customerEmail?.toLowerCase().includes(searchTerm.toLowerCase());
      
      if (selectedStatus === 'all') return matchesSearch;
      return matchesSearch && order.status?.toLowerCase() === selectedStatus.toLowerCase();
    });
  }, [orders, searchTerm, selectedStatus]);

  // Separate get paginated data functions for mobile and desktop
  const getMobileData = useCallback(() => {
    const endIndex = mobilePage * ITEMS_PER_PAGE.MOBILE;
    return filteredOrders.slice(0, endIndex);
  }, [filteredOrders, mobilePage]);

  const getDesktopData = useCallback(() => {
    const endIndex = page * ITEMS_PER_PAGE.DESKTOP;
    return filteredOrders.slice(0, endIndex);
  }, [filteredOrders, page]);

  // Separate scroll handlers for mobile and desktop
  const handleCardScroll = useCallback((e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    
    // Check if we're near bottom (within 100px)
    if (scrollHeight - scrollTop - clientHeight < 100) {
      if (!mobileLoading && mobileHasMore) {
        setMobileLoading(true);
        // Simulate API call
        setTimeout(() => {
          setMobilePage(prev => prev + 1);
          setMobileLoading(false);
        }, 500);
      }
    }
  }, [mobileLoading, mobileHasMore]);

  const handleTableScroll = useCallback((e) => {
    if (loading || !hasMore) return;

    const { scrollTop, scrollHeight, clientHeight } = e.target;
    
    if (scrollHeight - scrollTop - clientHeight < 100) {
      setLoading(true);
      setTimeout(() => {
        setPage(prev => prev + 1);
        setLoading(false);
      }, 500);
    }
  }, [loading, hasMore]);

  // Update mobile hasMore when data changes
  useEffect(() => {
    setMobileHasMore(getMobileData().length < filteredOrders.length);
  }, [getMobileData, filteredOrders.length]);

  // Reset mobile pagination on search/filter changes
  useEffect(() => {
    setMobilePage(1);
    setPage(1);
  }, [searchTerm, selectedStatus]);

  // Add scroll listener for mobile cards
  useEffect(() => {
    const cardContainer = cardContainerRef.current;
    if (cardContainer && window.innerWidth < 768) {
      cardContainer.addEventListener('scroll', handleCardScroll);
      return () => cardContainer.removeEventListener('scroll', handleCardScroll);
    }
  }, [handleCardScroll]);

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'completed':
        return 'px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800';
      case 'pending':
        return 'px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800';
      case 'cancelled':
        return 'px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800';
      default:
        return 'px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800';
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(price);
  };

  const handleExport = () => {
    const csvContent = "data:text/csv;charset=utf-8," 
      + "Order ID,Customer,Package,Amount,Date,Status\n"
      + orders.map(order => 
          `${order.orderNumber},${order.customerName},${order.package},${order.amount},${order.date},${order.status}`
        ).join("\n");
    
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "orders.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <AdminLayout>
      {/* Header */}
      <div className="mb-6">
        <h1 className="text-2xl font-bold text-gray-900">Orders</h1>
        <p className="mt-2 text-sm text-gray-600">Manage and track all orders</p>
      </div>

      {/* Filters and Search */}
      <div className="mb-6 flex flex-col md:flex-row gap-4">
        <div className="w-full md:w-auto md:flex-1">
          <div className="relative">
            <FaSearch className="absolute left-3 top-3 text-gray-400" />
            <input
              type="text"
              placeholder="Search orders..."
              className="w-full pl-10 pr-4 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-purple-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        <div className="flex gap-2 md:gap-4">
          <select
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            className="px-3 md:px-4 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-purple-500"
          >
            <option value="all">All Status</option>
            <option value="pending">Pending</option>
            <option value="completed">Completed</option>
            <option value="cancelled">Cancelled</option>
          </select>

          <button
            onClick={handleExport}
            className="px-3 md:px-4 py-2 bg-purple-500 text-white rounded-lg hover:bg-purple-600 transition-colors flex items-center gap-2"
          >
            <FaDownload className="shrink-0" />
            <span className="hidden md:inline">Export</span>
          </button>
        </div>
      </div>

      {/* Content Section with Fixed Height */}
      <div className="h-[calc(100vh-280px)] overflow-hidden">
        {/* Mobile Card View */}
        <div className="block md:hidden">
          <div 
            className="bg-gray-50 rounded-lg p-4"
            style={{ height: 'calc(100vh - 180px)' }}
          >
            <div 
              ref={cardContainerRef}
              className="h-full overflow-y-auto space-y-4 pr-2"
              onScroll={handleCardScroll}
            >
              {getMobileData().map((order) => (
                <div key={order.id} className="bg-white rounded-lg shadow-md p-4">
                  <div className="flex justify-between items-start mb-3">
                    <div>
                      <div className="font-medium text-gray-900">{order.orderNumber}</div>
                      <div className="text-sm text-gray-500">{order.customerName}</div>
                      <div className="text-xs text-gray-400">{order.customerEmail}</div>
                    </div>
                    <span className={getStatusColor(order.status)}>
                      {order.status}
                    </span>
                  </div>
                  <div className="mt-3 pt-3 border-t border-gray-100">
                    <div className="flex justify-between text-sm">
                      <span className="text-gray-500">{order.package}</span>
                      <span className="font-medium text-gray-900">{formatPrice(order.amount)}</span>
                    </div>
                    <div className="mt-2 text-xs text-gray-400">
                      {formatDate(order.date)}
                    </div>
                  </div>
                </div>
              ))}

              {/* Loading Indicator */}
              {mobileLoading && (
                <div className="flex justify-center py-4">
                  <div className="animate-spin rounded-full h-8 w-8 border-2 border-purple-500 border-t-transparent"></div>
                </div>
              )}

              {/* End of Content Message */}
              {!mobileHasMore && getMobileData().length > 0 && (
                <div className="text-center py-4 text-gray-500 text-sm">
                  No more orders to load
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Desktop Table View */}
        <div className="hidden md:block bg-white rounded-lg shadow-sm">
          <div 
            ref={tableContainerRef}
            className="overflow-auto"
            style={{ maxHeight: 'calc(100vh - 280px)' }}
            onScroll={handleTableScroll}
          >
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 sticky top-0 z-10">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Order ID
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Customer
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Package
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Amount
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {getDesktopData().map(order => (
                  <tr key={order.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">{order.orderNumber}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">{order.customerName}</div>
                      <div className="text-sm text-gray-500">{order.customerEmail}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{order.package}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">{formatPrice(order.amount)}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">{formatDate(order.date)}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={getStatusColor(order.status)}>
                        {order.status}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Loading Indicator */}
            {loading && (
              <div className="flex justify-center py-4 bg-white border-t">
                <div className="animate-spin rounded-full h-8 w-8 border-2 border-purple-500 border-t-transparent"></div>
              </div>
            )}

            {/* End of Content Message */}
            {!hasMore && getDesktopData().length > 0 && (
              <div className="text-center py-4 text-gray-500 border-t">
                No more orders to load
              </div>
            )}
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Orders;
