import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  ChatBubbleLeftRightIcon, 
  ClipboardDocumentListIcon,
  ShoppingBagIcon,
  UserCircleIcon
} from '@heroicons/react/24/outline';

const UserSidebar = ({ mobile, closeSidebar }) => {
  const location = useLocation();
  const userData = localStorage.getItem('logedinUser');

  const navigation = [
    { name: 'Dashboard', href: '/user', icon: UserCircleIcon },
    { name: 'Chat', href: '/user/chat', icon: ChatBubbleLeftRightIcon },
    { name: 'Questionnaire', href: '/user/questionnaire', icon: ClipboardDocumentListIcon },
    { name: 'My Orders', href: '/user/myorder', icon: ShoppingBagIcon },
  ];

  const isActive = (path) => location.pathname === path;

  const handleClick = () => {
    if (mobile && closeSidebar) {
      closeSidebar();
    }
  };

  return (
    <div className="flex h-full flex-col">
      <div className="px-12 flex flex-col flex-grow pt-5 bg-white overflow-y-auto">
        <div className=" mb-6">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <UserCircleIcon className="h-10 w-10 text-gray-400" />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-gray-700">{userData || 'User'}</p>
              <p className="text-xs text-gray-500">Customer</p>
            </div>
          </div>
        </div>

        <nav className="mt-5 flex-1 px-2 space-y-1">
          {navigation.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              onClick={handleClick}
              className={`
                group flex items-center px-2 py-2 text-sm font-medium rounded-md
                ${isActive(item.href)
                  ? 'bg-purple-50 text-purple-600'
                  : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'}
              `}
            >
              <item.icon
                className={`
                  mr-3 flex-shrink-0 h-6 w-6
                  ${isActive(item.href)
                    ? 'text-purple-600'
                    : 'text-gray-400 group-hover:text-gray-500'}
                `}
              />
              {item.name}
            </Link>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default UserSidebar;

