// src/adminpanel/pages/Chat.jsx
import React, { useState, useEffect, useRef } from 'react';
import { IoSend, IoSearch, IoEllipsisVertical, IoArrowBack } from 'react-icons/io5';
import { FaSmile, FaPaperclip } from 'react-icons/fa';

const Chat = () => {
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [message, setMessage] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const messagesEndRef = useRef(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

  useEffect(() => {
    // Fetch chats (mock data for now)
    const mockChats = [
      {
        id: 1,
        name: 'John Doe',
        avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
        lastMessage: { content: 'Hello there!', timestamp: '2023-04-10T10:30:00Z' },
        online: true,
        messages: [
          { id: 1, content: 'Hi, how are you?', sender: 'user', timestamp: '2023-04-10T10:25:00Z' },
          { id: 2, content: 'Hello there!', sender: 'admin', timestamp: '2023-04-10T10:30:00Z' },
        ]
      },
      {
        id: 2,
        name: 'Jane Smith',
        avatar: 'https://randomuser.me/api/portraits/women/1.jpg',
        lastMessage: { content: 'Sure, no problem.', timestamp: '2023-04-09T15:45:00Z' },
        online: false,
        messages: [
          { id: 1, content: 'Can you help me with something?', sender: 'user', timestamp: '2023-04-09T15:40:00Z' },
          { id: 2, content: 'Sure, no problem.', sender: 'admin', timestamp: '2023-04-09T15:45:00Z' },
        ]
      },
    ];
    setChats(mockChats);
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [selectedChat?.messages]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const sendMessage = (e) => {
    e.preventDefault();
    if (!message.trim() || !selectedChat) return;

    const newMessage = {
      id: selectedChat.messages.length + 1,
      content: message,
      sender: 'admin',
      timestamp: new Date().toISOString(),
    };

    setSelectedChat(prev => ({
      ...prev,
      messages: [...prev.messages, newMessage],
      lastMessage: { content: message, timestamp: newMessage.timestamp }
    }));

    setChats(prevChats => 
      prevChats.map(chat => 
        chat.id === selectedChat.id 
          ? { ...chat, lastMessage: { content: message, timestamp: newMessage.timestamp } }
          : chat
      )
    );

    setMessage('');
  };

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  return (
    <div className="flex h-[calc(100vh-4rem)] bg-gray-100">
      {/* Chat List */}
      <div className={`${
        isMobileView && selectedChat ? 'hidden' : 'block'
      } w-full md:w-1/3 lg:w-1/4 bg-white border-r flex flex-col max-h-[calc(100vh-4rem)]`}>
        {/* Search Header - Fixed height */}
        <div className="h-16 p-4 border-b">
          <div className="relative h-full">
            <IoSearch className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              placeholder="Search or start new chat"
              className="w-full h-full pl-10 pr-4 bg-gray-100 rounded-lg focus:outline-none"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        {/* Chats List - Remaining height */}
        <div className="flex-1 overflow-y-auto">
          {chats.map((chat) => (
            <div
              key={chat.id}
              className={`flex items-center h-20 px-4 cursor-pointer hover:bg-gray-50 border-b
                ${selectedChat?.id === chat.id ? 'bg-gray-100' : ''}`}
              onClick={() => setSelectedChat(chat)}
            >
              <div className="relative">
                <img
                  src={chat.avatar || `https://ui-avatars.com/api/?name=${chat.name}`}
                  alt={chat.name}
                  className="w-12 h-12 rounded-full"
                />
                {chat.online && (
                  <div className="absolute bottom-0 right-0 w-3 h-3 bg-green-500 rounded-full border-2 border-white" />
                )}
              </div>
              <div className="ml-4 flex-1">
                <div className="flex justify-between">
                  <h3 className="font-semibold text-gray-800">{chat.name}</h3>
                  <span className="text-xs text-gray-500">
                    {chat.lastMessage?.timestamp && formatTime(chat.lastMessage.timestamp)}
                  </span>
                </div>
                <p className="text-sm text-gray-600 truncate">{chat.lastMessage?.content}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Chat Area */}
      <div className={`${
        isMobileView && !selectedChat ? 'hidden' : 'flex'
      } flex-1 flex flex-col h-[calc(100vh-4rem)]`}>
        {selectedChat ? (
          <>
            {/* Chat Header - Fixed height */}
            <div className="h-16 flex items-center justify-between px-4 bg-white border-b">
              <div className="flex items-center">
                {isMobileView && (
                  <button
                    onClick={() => setSelectedChat(null)}
                    className="mr-2 p-2 hover:bg-gray-100 rounded-full"
                  >
                    <IoArrowBack className="w-6 h-6 text-gray-500" />
                  </button>
                )}
                <img
                  src={selectedChat.avatar || `https://ui-avatars.com/api/?name=${selectedChat.name}`}
                  alt={selectedChat.name}
                  className="w-10 h-10 rounded-full"
                />
                <div className="ml-4">
                  <h3 className="font-semibold text-gray-800">{selectedChat.name}</h3>
                  <p className="text-xs text-gray-500">
                    {selectedChat.online ? 'Online' : 'Offline'}
                  </p>
                </div>
              </div>
              <button className="p-2 hover:bg-gray-100 rounded-full">
                <IoEllipsisVertical className="w-5 h-5 text-gray-500" />
              </button>
            </div>

            {/* Messages Area - Calculated height */}
            <div className="flex-1 bg-violet-50 overflow-y-auto">
              <div className="max-w-3xl mx-auto px-4 py-6 space-y-4">
                {selectedChat.messages?.map((msg) => (
                  <div
                    key={msg.id}
                    className={`flex ${msg.sender === 'admin' ? 'justify-end' : 'justify-start'}`}
                  >
                    <div
                      className={`max-w-[85%] md:max-w-[70%] rounded-lg p-3 ${
                        msg.sender === 'admin'
                          ? 'bg-[#d1d1ce] ml-auto'
                          : 'bg-white'
                      }`}
                    >
                      <p className="text-gray-800 break-words">{msg.content}</p>
                      <p className="text-xs text-gray-500 text-right mt-1">
                        {formatTime(msg.timestamp)}
                      </p>
                    </div>
                  </div>
                ))}
                <div ref={messagesEndRef} />
              </div>
            </div>

            {/* Message Input - Fixed height */}
            <div className="h-16 bg-white border-t px-4">
              <form onSubmit={sendMessage} className="h-full flex items-center gap-2 max-w-3xl mx-auto">
                <button
                  type="button"
                  className="h-10 w-10 flex items-center justify-center hover:bg-gray-100 rounded-full text-gray-500 hidden md:flex"
                >
                  <FaSmile className="w-5 h-5" />
                </button>
                <button
                  type="button"
                  className="h-10 w-10 flex items-center justify-center hover:bg-gray-100 rounded-full text-gray-500 hidden md:flex"
                >
                  <FaPaperclip className="w-5 h-5" />
                </button>
                <input
                  type="text"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Type a message"
                  className="flex-1 h-10 px-4 bg-gray-100 rounded-lg focus:outline-none"
                />
                <button
                  type="submit"
                  disabled={!message.trim()}
                  className="h-10 w-10 flex items-center justify-center bg-purple-500 hover:bg-purple-600 rounded-full text-white disabled:opacity-50"
                >
                  <IoSend className="w-5 h-5" />
                </button>
              </form>
            </div>
          </>
        ) : (
          <div className="flex-1 flex items-center justify-center bg-violet-50">
            <div className="text-center">
              <h3 className="text-xl font-semibold text-gray-700 mb-2">Welcome to Chat</h3>
              <p className="text-gray-500">Select a conversation to start messaging</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Chat;
