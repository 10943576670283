import React from 'react';
import { FaFacebookF, FaTwitter, FaLinkedinIn } from 'react-icons/fa';
import Footer from '../../components/footer/Footer';
import Navbar from '../../components/navbar/Navbar';

const Contact = () => {
  return (
    <>
    <Navbar/>
    <div className="bg-violet-100 py-16 pt-28">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-bold text-indigo-500 text-center mb-12">Contact Us</h1>

        <div className="flex flex-col md:flex-row justify-between items-start bg-violet-50 rounded-lg shadow-lg p-8 md:p-16">
          {/* Left Section */}
          <div className="md:w-1/2 mb-8 md:mb-0 pr-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-6">Get in Touch</h2>
            <p className="text-lg text-gray-700 mb-6">
              For any questions or assistance, please don’t hesitate to reach out to us. Our dedicated team is available to provide support and will get back to you as promptly as possible, ensuring you receive the help you need.
            </p>
            <p className="text-lg text-gray-700 mb-4"><strong>Email:</strong> info@peerlessresume.com</p>
            <p className="text-lg text-gray-700 mb-4"><strong>Phone:</strong> +1 (908) 340 0341</p>
            <p className="text-lg text-gray-700 mb-4"><strong>Business Hours:</strong></p>
            <ul className="text-lg text-gray-700 mb-6">
              <li>Monday - Friday: 9:00 AM - 5:00 PM</li>
              <li>Saturday: 10:00 AM - 3:00 PM</li>
              <li>Sunday: Closed</li>
            </ul>
            <p className="text-lg text-gray-700 mb-4"><strong>Connect with us on social media:</strong></p>
            <div className="flex space-x-4 text-indigo-500">
              <a href="#" className="hover:text-indigo-600 transition duration-300"><FaFacebookF /></a>
              <a href="#" className="hover:text-indigo-600 transition duration-300"><FaTwitter /></a>
              <a href="#" className="hover:text-indigo-600 transition duration-300"><FaLinkedinIn /></a>
            </div>
            <p className="text-lg text-gray-700 mt-6"><strong>Need help?</strong> Check our <a href="/faqs" className="text-indigo-500 hover:underline">FAQs</a> for more information.</p>
          </div>

          {/* Right Section */}
          <div className="md:w-1/2 bg-gray-50 rounded-lg p-8 md:ml-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-6">Submit Your Request</h2>
            <form className="space-y-6">
              <div>
                <label className="block text-lg text-gray-700 mb-2" htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-indigo-500 focus:outline-none"
                  placeholder="Your Name"
                />
              </div>
              <div>
                <label className="block text-lg text-gray-700 mb-2" htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-indigo-500 focus:outline-none"
                  placeholder="Your Email"
                />
              </div>
              <div>
                <label className="block text-lg text-gray-700 mb-2" htmlFor="message">Message</label>
                <textarea
                  id="message"
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-indigo-500 focus:outline-none"
                  rows="6"
                  placeholder="Your Message"
                ></textarea>
              </div>
              <div className="text-right">
                <button
                  type="submit"
                  className="px-8 py-3 bg-violet-200  font-semibold rounded-full shadow-md hover:bg-violet-300 transition duration-300 ease-in-out"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default Contact;
